import axios from 'axios';
import Keycloak from 'keycloak-js';
import Vue from 'vue';

export default ({
    namespaced: true,
    state: {
        keycloak: null,
        profile: null,
        tokenParsed: null,
        status: false
    },
    getters: {
        getStatus: function (state) {
            return state.status;
        },
        getKeycloak: function (state) {
            return state.keycloak;
        },
        getProfile: function (state) {
            return state.profile;
        },
        getToken: function (state) {
            return state.tokenParsed;
        },
        getInitials: function (state) {
            var i = "";
            if (!state.profile) return i;
            if (state.profile.firstName) i += state.profile.firstName.slice(0, 1);
            if (state.profile.lastName) i += state.profile.lastName.slice(0, 1);
            return i;
        },
        hasClientRoles: (state) => (roleArray) => {
            if (!state.tokenParsed || !state.profile || Object.keys(state.tokenParsed).indexOf("client_roles") < 0) return false;
            let roles = state.tokenParsed["client_roles"];
            let decapitalize = (role) => { return role.toLowerCase(); };
            for (let i = 0; i < roleArray.length; i++) {
                if (roles.map(decapitalize).indexOf(roleArray[i]) < 0) {
                    return false;
                }
            }
            return true;
        }
    },
    mutations: {
        setKeycloakConnection: function (state, payload) {
            if (payload.keycloak == undefined) throw "Payload is missing property, 'keycloak'";
            Vue.set(state, "keycloak", payload.keycloak);
        },
        setKeycloakProfile: function (state, payload) {
            if (payload.profile == undefined) throw "Payload is missing property, 'profile'";
            Vue.set(state, "profile", payload.profile);
        },
        setTokenParsed: function (state, payload) {
            if (payload.tokenParsed == undefined) throw "Payload is missing property, 'tokenParsed'";
            Vue.set(state, "tokenParsed", payload.tokenParsed);
        },
        setStatus: function (state, payload) {
            if (payload.status == undefined) throw "Payload is missing property, 'status'";
            Vue.set(state, "status", payload.status);
        }
    },
    actions: {
        tryLogin: function (context) {
            context.state.keycloak.login({ redirectUri: window.location.href });
        },
        tryLogout: function (context) {
            localStorage.removeItem("kc_token");
            localStorage.removeItem("kc_refresh_token");
            context.state.keycloak.logout({ redirectUri: window.location.origin });
        },
        initKeycloak: async function (context) {
            return new Promise((resolve) => {
                axios.get("kc/getcredentials").then((credentials) => {
                    var initOptions = {
                        url: credentials.data.url + "/auth",
                        realm: credentials.data.realm,
                        clientId: credentials.data.clientId,
                        checkLoginIframe: false,
                        onLoad: "check-sso"
                    };
                    var kc = Keycloak(initOptions);
                    context.commit("setKeycloakConnection", { keycloak: kc });
                    return kc.init({
                        onLoad: initOptions.onLoad,
                        checkLoginIframe: initOptions.checkLoginIframe,
                        token: localStorage.getItem("kc_token"),
                        refreshToken: localStorage.getItem("kc_refresh_token")
                    }).then((isAuthenticated) => {
                        if (isAuthenticated) {
                            return context.state.keycloak.loadUserProfile().then((profile) => {
                                localStorage.setItem("kc_token", context.state.keycloak.token);
                                localStorage.setItem("kc_refresh_token", context.state.keycloak.refreshToken);
                                context.commit("setKeycloakProfile", { profile: profile });
                                context.commit("setTokenParsed", { tokenParsed: context.state.keycloak.tokenParsed });
                                setInterval(() => {
                                    if (context.state.keycloak.isTokenExpired()) {
                                        context.state.keycloak.updateToken().then((status) => {
                                            if (status) {
                                                localStorage.setItem("kc_token", context.state.keycloak.token);
                                                localStorage.setItem("kc_refresh_token", context.state.keycloak.refreshToken);
                                                context.commit("setTokenParsed", { tokenParsed: context.state.keycloak.tokenParsed });
                                            }
                                        }).catch((e) => {
                                            console.warn("Failed to update token:", e.message);
                                        });
                                    }
                                }, 5 * 1000);
                            })
                        }
                        return;
                    })
                }).finally(() => {
                    context.commit("setStatus", { status: true });
                    resolve();
                });
            });
        }
    }
})