<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['manager'])">
            <h2>{{ getText("cool-obj-types") }}</h2>
            <div class="table-options">
                <span class="label">{{ getText("options") }}</span>
                <button @click="openNewType">
                    <i class="dexcon-plus"></i>
                    <span>{{ getText("add-class") }}</span>
                </button>
                <button @click="tryLoadTypes">
                    <i class="dexcon-reload"></i>
                    <span>{{ getText("reload") }}</span>
                </button>
            </div>
            <div class="table-wrapper">
                <table class="clickable" style="min-height: 50vh;">
                    <thead>
                        <tr>
                            <th @click="keySort(types, 'name')" :title="getText('sort-az')" sortable>{{ getText("cool-obj-type") }}</th>
                            <th @click="keySort(types, 'lowerLimit')" :title="getText('sort-az')" sortable>{{ getText("temp-bound-3") }}</th>
                            <th @click="keySort(types, 'normalLowerLimit')" :title="getText('sort-az')" sortable>{{ getText("temp-bound-2") }}</th>
                            <th @click="keySort(types, 'normalUpperLimit')" :title="getText('sort-az')" sortable>{{ getText("temp-bound-1") }}</th>
                            <th @click="keySort(types, 'upperLimit')" :title="getText('sort-az')" sortable>{{ getText("temp-bound-0") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="types.length == 0">
                            <td colspan="5" align="center">
                                {{ getText(`table-status-${tableStatus}`) }}
                            </td>
                        </tr>
                        <tr v-for="type in types" :key="type.id" @click="openType(type)">
                            <td>{{ type.name }}</td>
                            <td align="center" v-html="getTemp(type.lowerLimit, 'celcius')"></td>
                            <td align="center" v-html="getTemp(type.normalLowerLimit)"></td>
                            <td align="center" v-html="getTemp(type.normalUpperLimit)"></td>
                            <td align="center" v-html="getTemp(type.upperLimit)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p v-if="tableTs" class="table-detail">{{ getText("last-update") }}: {{ tableTs }}</p>
            <CoolingObjectTypeDetail v-if="popup.name == 'type'" @closePopup="closePopup" :data="popup.data" @reloadParent="tryLoadTypes" @reload="reloadHandler"></CoolingObjectTypeDetail>
        </div>
        <StandardRedirect v-else :targetRoute="'/'"></StandardRedirect>
    </div>
</template>

<script>
    import axios from "axios"
    import Vue from "vue"
    import { mapActions, mapGetters } from "vuex"
    import CoolingObjectTypeDetail from "@/views/coolingObjectTypes/CoolingObjectTypeDetail.vue"
    import StandardRedirect from "@/components/default/StandardRedirect.vue"

    export default {
        name: "TypeManagement",
        components: {
            CoolingObjectTypeDetail,
            StandardRedirect
        },
        data() {
            return {
                types: [],
                popup: {
                    name: null,
                    data: null
                },
                tableStatus: 0,
                tableTs: null
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getStatus: "getStatus",
                hasClientRoles: "hasClientRoles"
            }),
            ...mapGetters("temp", {
                getTemp: "getConvertedTemperatureAsString",
                getTempFloat: "getConvertedTemperatureAsFloat"
            }),
            isModified: function () {
                let compare = (key) => { return this.targetData[key] == this.initData[key]; };
                let checkForMod = (acc, iv) => { return acc + (iv == false); };
                return Object.keys(this.initData).map(compare).reduce(checkForMod, 0) > 0;
            }
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            tryLoadTypes: function () {
                this.replaceLoader({ loader: true });
                this.tableStatus = 0;
                return axios.get("/api/GetAllCoolingObjectTypes").then(res => {
                    if (res.data.requestSuccess) {
                        Vue.set(this, "types", res.data.result);
                        this.tableStatus = 1;
                        this.tableTs = new Date().toLocaleString();
                    } else {
                        throw (res.data.message);
                    }
                }).catch((e) => {
                    alert("Failed to load types: " + e);
                    this.tableStatus = 2;
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            closePopup: function () {
                this.popup = { name: null, data: null };
            },
            openType: function (data) {
                let dataCopy = { ...data };
                const lims = ["upperLimit", "lowerLimit", "normalUpperLimit", "normalLowerLimit"];
                lims.map(o => { dataCopy[o] = this.getTempFloat(dataCopy[o], 'celcius'); });
                this.popup = { name: "type", data: dataCopy };
            },
            openNewType: function () {
                this.popup = {
                    name: "type", data: {
                        name: null,
                        lowerLimit: null,
                        normalLowerLimit: null,
                        normalUpperLimit: null,
                        upperLimit: null
                    }
                };
            },
            reloadHandler: async function () {
                await this.tryLoadTypes();
                let targetId = this.popup.data.id;
                this.closePopup();
                this.$nextTick(() => {
                    this.openType(this.types.find(e => e.id == targetId));
                });
            },
            init: function () {
                if (!this.hasClientRoles(['manager'])) return;
                this.tryLoadTypes();
            },
            keySort: function (arr, key) {
                arr.sort((a, b) => {
                    return a[key] > b[key] ? 1 : -1;
                });
            }
        },
        watch: {
            getStatus: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
</style>