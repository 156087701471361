<template>
    <div class="pad-child overview" align="center">
        <div v-if="hasClientRoles(['viewer'])">
            <h2>{{ getText("status") }}</h2>
            <div class="table-options">
                <span class="label">{{ getText("view") }}</span>
                <select v-model="viewType">
                    <option value="spatial" default>{{ getText("spatial-model") }}</option>
                    <option value="tabular">{{ getText("tabular") }}</option>
                </select>
                <span v-if="viewType == 'spatial'" class="label">{{ getText("sort-by") }}</span>
                <select v-if="viewType == 'spatial'" v-model="sortLogic">
                    <option value="name-az" default>{{ getText("name-az") }}</option>
                    <option value="warn-az">{{ getText("warn-az") }}</option>
                </select>
                <span class="label">{{ getText("options") }}</span>
                <button @click="reloadHandler">
                    <i class="dexcon-reload"></i>
                    <span>{{ getText("reload") }}</span>
                </button>
            </div>
            <StatusSpatialModel v-if="viewType == 'spatial'" @viewObject="viewObjectHandler" :reloadTs="reloadTs" :sortLogic="sortLogic"></StatusSpatialModel>
            <StatusTabular v-else-if="viewType == 'tabular'" @viewObject="viewObjectHandler" :reloadTs="reloadTs"></StatusTabular>
            <CoolingObjectDetail v-if="popup.name == 'object'" @closePopup="closePopup" @reload="reloadHandler" :initData="popup.data" :initTs="popup.data.tableTs" :coTypes="availableTypes"></CoolingObjectDetail>
        </div>
        <StandardRedirect v-else :targetRoute="'/'"></StandardRedirect>
    </div>
</template>

<script>
    import axios from "axios"
    import CoolingObjectDetail from "@/views/status/CoolingObjectDetail.vue"
    import StatusTabular from "@/views/status/StatusTabular.vue"
    import StatusSpatialModel from "@/views/status/StatusSpatialModel.vue"
    import { mapActions, mapGetters } from "vuex"
    import StandardRedirect from "@/components/default/StandardRedirect.vue"

    export default {
        name: "Status",
        components: {
            StandardRedirect,
            CoolingObjectDetail,
            StatusTabular,
            StatusSpatialModel
        },
        data() {
            return {
                viewType: null,
                initType: null,
                targetType: null,
                popup: {
                    name: null,
                    data: null
                },
                reloadTs: null,
                availableTypes: [],
                sortLogic: "name-az"
            }
        },
        computed: {
            ...mapGetters("keycloak", {
                hasClientRoles: "hasClientRoles",
                getStatus: "getStatus"
            }),
            ...mapGetters("lang", {
                getText: "getText"
            })
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            tryLoadObjectTypes: function () {
                if (!this.hasClientRoles(['manager'])) return;
                return axios.get("api/GetAllCoolingObjectTypes").then((res) => {
                    if (res.data.requestSuccess) {
                        this.availableTypes = res.data.result;
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    alert("Failed to load object types: " + e);
                });
            },
            closePopup: function () {
                this.popup = { name: null, data: null };
                this.$nextTick(() => {
                    let query = Object.assign({}, this.$route.query);
                    delete query.coid;
                    this.$router.replace({ query }).catch(e => {
                        if (e.name != "NavigationDuplicated") throw e;
                    });
                });
            },
            viewObjectHandler: function (data) {
                this.popup = { name: "object", data: data };
            },
            reloadHandler: function () {
                this.reloadTs = new Date().getTime();
            },
            init() {
                if (!this.hasClientRoles(['viewer'])) return;
                this.viewType = window.localStorage.getItem("viewType");
                if (!this.viewType) {
                    window.localStorage.setItem("viewType", "spatial");
                    this.viewType = "spatial";
                }
                this.sortLogic = window.localStorage.getItem("sortLogic");
                if (!this.sortLogic) {
                    window.localStorage.setItem("sortLogic", "warn-az");
                    this.sortLogic = "warn-az";
                }
                this.tryLoadObjectTypes();
            }
        },
        watch: {
            getStatus: function () {
                this.init();
            },
            viewType: function () {
                window.localStorage.setItem("viewType", this.viewType);
            },
            sortLogic: function () {
                window.localStorage.setItem("sortLogic", this.sortLogic);
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
</style>
<style>
    .overview .tag {
        color: var(--stay-white);
        background: var(--iss-grey);
        border-radius: var(--margin-025);
        padding: var(--margin-0125) var(--margin-025);
        text-transform: uppercase;
        font-weight: var(--bolder);
        display: inline-block;
        font-size: var(--small);
        text-align: center;
        margin-bottom: 0;
        vertical-align: middle;
    }

        .overview .tag.temp-0,
        .overview .tag.sensor-0 {
            background: var(--free-green);
        }

        .overview .tag.temp-1 {
            background: var(--idle-orange);
        }

        .overview .tag.temp-2,
        .overview [class*="sensor-"]:not(.sensor-0) {
            background: var(--iss-red);
        }

    .table-options {
        margin-bottom: var(--margin-05);
    }
</style>