<template>
    <div>
        <h2>{{ getText("user-management") }}</h2>
       <div class="table-options">
           <span class="label">{{ getText("role") }}</span>
           <select v-model="targetRole">
               <option v-for="role in storeRoleList" :key="role">{{ role }}</option>
           </select>           
           <span class="label">{{ getText("options") }}</span>
           <button @click="tryLoadUsers">
               <i class="dexcon-reload"></i>
               <span>{{ getText("reload") }}</span>
           </button>
       </div>
        <div class="table-wrapper">
            <table class="clickable" style="height: 50vh;">
                <thead>
                    <tr>
                        <th @click="storeUserKeySort({key: 'firstName'})" :title="getText('sort-az')" sortable>{{ getText("first-name") }}</th>
                        <th @click="storeUserKeySort({key: 'lastName'})" :title="getText('sort-az')" sortable>{{ getText("last-name") }}</th>
                        <th @click="storeUserKeySort({key: 'email'})" :title="getText('sort-az')" sortable>{{ getText("email") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="storeUsers.length == 0">
                        <td colspan="3" align="center">
                            {{ getText(`table-status-${tableStatus}`) }}
                        </td>
                    </tr>
                    <tr v-for="user in storeUsers" :key="user.id" @click="viewUser(user)">
                        <td>{{ user.firstName }}</td>
                        <td>{{ user.lastName }}</td>
                        <td>{{ user.email }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-detail" v-if="storeTableTs">{{ getText("last-update") }}: {{ storeTableTs }}</div>
    </div>
</template>

<script>
    import axios from "axios"
    import { mapActions, mapGetters } from "vuex"

    export default {
        name: "UsersByRolePage",
        data() {
            return {
                isMounted: false,
                targetRole: null,
                availableRoles: [],
                tableStatus: 0
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile"
            }),
            ...mapGetters("userManagement", {
                storeTargetRole: "getTargetRole",
                storeUsers: "getUserList",
                storeTableTs: "getTableTs",
                storeRoleList: "getRoleList"
            })
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            ...mapActions("userManagement", {
                replaceSubpage: "replaceSubpage",
                replaceTargetRole: "replaceTargetRole",
                replaceUserList: "replaceUserList",
                replaceTableTs: "replaceTableTs",
                replaceRoleList: "replaceRoleList",
                storeUserKeySort: "storeUserKeySort"
            }),
            tryGetAvailableRoles: function () {
                this.replaceLoader({ loader: true });
                this.availableRoles = [];
                return axios.get("/api/roles").then(res => {
                    if (res.data.requestSuccess) {
                        this.replaceRoleList({ roleList: res.data.result });
                        this.targetRole = res.data.result[0];
                    } else {
                        throw res.data.message;
                    }
                }).catch(e => {
                    alert("Failed to load available roles: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                })
            },
            tryLoadUsers: function () {
                if (!this.targetRole) return;
                this.replaceLoader({ loader: true });
                this.replaceUserList({ userList: [] });
                this.tableStatus = 0;
                this.replaceTableTs({ tableTs: null });
                return axios.get(`/api/UsersByRole/${this.targetRole}`).then(res => {
                    if (res.data.requestSuccess) {
                        this.replaceUserList({ userList: res.data.result });
                        this.replaceTableTs({ tableTs: new Date().toLocaleString() });
                        this.tableStatus = 1;
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    alert("Failed to load users: " + e);
                    this.tableStatus = 2;
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            viewUser: function (data) {
                this.replaceSubpage({ subpage: { name: 'user', data: data } });
            },
            init: function () {
                if (!this.getProfile) return;
                if (this.storeUsers.length == 0) {
                    this.tryLoadUsers();
                    this.tryGetAvailableRoles();
                }
            },
            keySort: function (arr, key) {
                arr.sort((a, b) => {
                    return a[key] > b[key] ? 1 : -1;
                });
            }
        },
        watch: {
            targetRole: function () {
                if (!this.isMounted) return;
                this.tryLoadUsers();
                this.replaceTargetRole({ role: this.targetRole });
            },
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            if (this.storeTargetRole) {
                this.targetRole = this.storeTargetRole;
                this.availableRoles = this.storeRoleList;
            }
            this.$nextTick(() => {
                this.init();
                this.tableStatus = 1;
                this.isMounted = true;
            });
        }
    }
</script>