<template>
    <div class="pad-child">
        <Splash>
            <div class="splash-content">
                <p class="label">{{ getText("splash-headline-label") }}</p>
                <h1>{{ getText("splash-headline") }}</h1>
                <p>{{ getText("splash-text") }}</p>
                <a href="https://www.de.issworld.com/de-de/services/services,-die-wir-anbieten/workplace"
                   target="_blank"
                   class="dex-button box">
                    <span>{{ getText("splash-prompt") }}</span> <i class="dexcon-chevron-right-thin"></i>
                </a>
            </div>
            <img src="../../../public/splash.jpg" />
        </Splash>
        <div v-if="!getProfile" align="center">
            <h2>{{ getText("login-to-proceed") }}</h2>
            <button class="box outline" @click="tryLogin">
                <span>{{ getText("login") }}</span><i class="dexcon-chevron-right-thin"></i>
            </button>
        </div>
    </div>
</template>

<script>
    import Splash from "@/components/default/Splash.vue"
    import { mapGetters } from "vuex"

    export default {
        name: "Home",
        components: {
            Splash,
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getKeycloak: "getKeycloak",
                getProfile: "getProfile"
            })
        },
        methods: {
            tryLogin: function () {
                this.getKeycloak.login();
            }
        }
    };
</script>
