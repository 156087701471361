<template>
    <header ref="header" class="floating">
        <ul class="header-list">
            <li class="page-title">
                <a @click="$router.push('/').catch(e => { e; })">
                    <img class="logo" src="@/assets/images/iss-logo.svg" />
                    <p v-if="appName" v-html="appName" class="app-name"></p>
                </a>
            </li>
            <li class="nav">
                <ul>
                    <slot>
                        This component up to two <kbd>DexHeaderNavSection</kbd> components in this slot.
                    </slot>
                </ul>
            </li>
        </ul>
        <button class="hamburger color-change dexcon-staggered-hamburger" ref="hamburger" @click="toggleHeader"></button>
    </header>
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        name: "DexHeader",
        props: ["appName"],
        data() {
            return {
                lastScrollPos: 0,
                lastScrollDelta: 0
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            })
        },
        methods: {
            collapseHeaderHandler: function () {
                if (window.innerWidth <= 768) {
                    this.$refs.header.classList.add("collapsed");
                } else {
                    this.$refs.header.classList.remove("collapsed");
                    this.$refs.header.classList.remove("expanded");
                }
                if (this.$refs.header.classList.contains("expanded")) {
                    this.toggleHeader();
                }
                document.querySelector(":root").style.setProperty("--header-height", window.getComputedStyle(document.querySelector("header")).height);
            },
            collapseHeader: function () {
                this.$refs.header.classList.remove("expanded");
            },
            toggleHeader: function () {
                if (
                    this.$refs.header.classList.contains("expanded")
                ) {
                    this.collapseHeader();
                } else {
                    this.$refs.header.classList.add("expanded");
                }
            },
            tryRename: function() {
                document.querySelector("title").innerHTML = this.$route.name.toLowerCase() == 'home' ? this.appName : `${this.$route.name} - ${this.appName}`;
            }
        },
        watch: {
            $route: function () {
                this.tryRename();
            }
        },
        mounted() {
            this.collapseHeaderHandler();
            this.tryRename();
            window.addEventListener("resize", () => {
                this.collapseHeaderHandler();
            });
            window.addEventListener("scroll", () => {
                this.lastScrollDelta = document.querySelector("html").scrollTop - this.lastScrollPos;
                if (document.querySelector("html").scrollTop >= window.getComputedStyle(document.querySelector("header")).height.slice(0, -2) * 0.5) {
                    if (!document.querySelector("header.expanded")) {
                        document.querySelector("header").classList.add("hidden");
                    }
                    if (this.lastScrollDelta < 0) {
                        document.querySelector("header").classList.remove("hidden");
                    }
                } else {
                    document.querySelector("header").classList.remove("hidden");
                }
                this.lastScrollPos = document.querySelector("html").scrollTop;
            });
        }
    }
</script>

<style scoped>
    header {
        z-index: 4 !important;
        height: calc(45px + 2 * var(--header-margin));
        position: relative;
    }

        header.floating {
            position: fixed;
            width: 100%;
            max-width: var(--max-width);
            transform: translateY(0);
            transition: transform var(--quick) ease;
        }

            header.floating.hidden {
                transform: translateY(calc(-1 * var(--header-height)));
            }

    .header-list {
        background: var(--white);
        color: var(--iss-blue);
        padding: var(--header-margin) var(--side-margin-15);
        list-style-type: none;
        display: flex;
    }

    .page-title > a > * {
        display: inline-block;
        vertical-align: middle;
    }

    .logo {
        height: 46px;
        margin-top: -4px;
    }

    .app-name {
        color: var(--iss-blue);
        font-size: var(--smaller);
        font-weight: var(--bold);
        text-transform: uppercase;
        margin: 0;
        margin-top: 2px;
        margin-left: 12px;
    }

    .nav {
        flex-grow: 1;
        justify-content: center;
    }

        .nav > ul {
            list-style-type: none;
            display: flex;
            flex-grow: 1;
            height: calc(100% - 1px);
            align-items: center;
            margin-top: 1px;
        }

            .nav > ul > li:first-child {
                display: flex;
                flex-grow: 1;
                justify-content: center;
            }

    .hamburger {
        position: absolute;
        top: 0;
        right: 0;
        font-size: var(--x-large);
        padding: var(--header-margin) var(--side-margin-15);
        height: calc(45px + 2 * var(--header-margin));
        display: none;
    }

    @media screen and (min-width: 768px) {
        .hamburger {
            font-size: var(--larger);
        }
    }

    header.collapsed {
        overflow: hidden;
        z-index: 2;
    }

        header.collapsed > ul {
            display: block;
        }

        header.collapsed .nav {
            margin-top: calc(1.5 * var(--header-margin));
            height: calc(100% - 45px - 2 * var(--header-margin));
            overflow: auto;
        }

        header.collapsed .hamburger {
            display: inline-block !important;
        }

        header.collapsed.expanded {
            position: fixed;
            max-width: var(--max-width);
            top: 0px;
            left: 0;
            width: 100%;
            height: 100%;
        }

            header.collapsed.expanded .hamburger::before {
                content: "\e901"
            }

    @media screen and (min-width: 1440px) {
        header.collapsed.expanded {
            margin: 0 calc((100% - var(--max-width)) * 0.5);
        }
    }

    header.collapsed.expanded .header-list {
        background: white;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        height: 100vh;
    }

    header.collapsed .nav > ul {
        display: flex;
        flex-direction: column;
    }

        header.collapsed .nav > ul > li {
            width: 100%;
        }

            header.collapsed .nav > ul > li:nth-child(1) {
                order: 2;
            }

            header.collapsed .nav > ul > li:nth-child(2) {
                order: 1;
            }
</style>

<style>
    header + * {
        margin-top: calc(45px + 2 * var(--header-margin));
    }

    header .nav-item {
        font-size: 0;
        list-style-type: none !important;
        color: var(--iss-blue);
        text-transform: uppercase;
        cursor: pointer;
        font-weight: var(--bolder);
        text-align: center;
        position: relative;
        margin-top: 1px !important;
        display: flex;
        align-items: center;
    }

        header .nav-item > a {
            font-size: var(--smaller);
            padding: 0 var(--margin-075);
        }

    header .user-item > a {
        font-size: var(--medium);
        padding: 0 var(--margin-05);
    }

    header .user-item > img:last-child {
        padding-left: var(--margin-05);
    }

    header .user-item {
        font-size: 0;
        position: relative;
        list-style-type: none !important;
        color: var(--iss-blue);
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    @media (hover: hover) and (pointer: fine) {
        header .nav-item.expanded:hover::after,
        header .user-item.expanded::after {
            content: " ";
            height: calc(100% + 2 * var(--margin-1));
            width: 100%;
            position: absolute;
            top: 0;
            left: 0%;
        }
    }


    header .user-item:last-child > a {
        padding-right: 0 !important;
    }

    @media screen and (hover: hover) and (pointer: fine) {
        /* Underline for nav items */
        header .nav-item > a::before {
            content: " ";
            position: absolute;
            top: 155%;
            left: 50%;
            height: 4px;
            width: calc(100% - 2 * var(--margin-075));
            background: var(--azure-blue);
            transform: translate(-50%, 0%) scaleX(0);
            transition: transform ease var(--quick);
        }

        header .nav-item:hover > a::before {
            transform: translate(-50%, 0%) scaleX(1);
        }

        header.collapsed .nav-item > a::before {
            content: none;
        }
    }

    header .nav-item > a,
    header .user-item > a {
        flex-grow: 1;
    }


    header .nav-item a,
    header .user-item a {
        color: var(--iss-blue);
        font-weight: inherit;
    }

    @media screen and (hover: hover) and (pointer: fine) {
        header .nav-item:hover > a,
        header .user-item:hover > a {
            color: var(--azure-blue);
        }
    }

    header.collapsed .nav-item {
        padding: var(--margin-1) 0 !important;
        text-transform: unset !important;
        font-weight: var(--normal) !important;
        text-align: left;
    }

        header.collapsed .nav-item a {
            font-size: var(--xx-large) !important;
        }

    header.collapsed .user-item {
        padding: var(--margin-1) 0 !important;
        font-size: var(--xx-large) !important;
    }

        header.collapsed .user-item > a {
            padding: 0 var(--margin-075);
            font-size: var(--x-large) !important;
        }

    @media (min-width: 575px) {
        header.collapsed .nav-item {
            padding: var(--margin-05) 0 !important;
            font-size: var(--xx-large) !important;
        }

        header.collapsed .user-item {
            padding: var(--margin-05) 0 !important;
            font-size: var(--x-large) !important;
        }
    }

    header .dropdown {
        background: var(--white);
        box-shadow: var(--shadow-soft);
        padding: calc(1.5 * var(--margin-025));
        width: 40rem;
        max-height: 70vh;
        overflow: auto;
        position: absolute;
        left: 50%;
        top: calc(100% + var(--header-margin));
        transform: translateX(-50%);
        animation: slide-out ease var(--quick) forwards;
        display: none;
        z-index: 3 !important;
        text-align: left;
        cursor: default;
    }

    header .expanded .dropdown {
        display: inline-block;
        animation: slide-in ease var(--quick) forwards;
    }

    @keyframes slide-in {
        0% {
            transform: translate(-50%, 5px);
            opacity: 0;
        }

        100% {
            transform: translate(-50%, 0);
            opacity: 1;
        }
    }

    header .dropdown button.back {
        display: none;
        font-size: var(--large);
        width: 100%;
        padding: var(--margin-075);
        margin-bottom: var(--margin-1);
    }

    header.collapsed .expanded .dropdown {
        padding: var(--header-margin) var(--side-margin-15);
        padding-top: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 45px - 3 * var(--header-margin));
        max-height: calc(100% - 45px - 2 * var(--header-margin));
        transform: translate(0, calc(45px + 2 * var(--header-margin))) !important;
        box-shadow: none;
        animation: fly-left var(--quick) ease forwards !important;
    }

    @keyframes fly-left {
        0% {
            left: 100%;
        }

        100% {
            left: 0;
        }
    }

    header.collapsed .expanded .dropdown button.back {
        display: block;
    }
</style>