<template>
    <div>
        <PopupWindow @closePopup="closePopup" :coverWindow="true">
            <p class="label">{{ getText("cool-obj-types") }}</p>
            <h3>{{ name ? name : getText("untitled-cool-obj-type") }}</h3>
            <div v-if="newFlag" class="label-group">
                <p class="label">{{ getText("name") }}</p>
                <input type="text" v-model="innerData.name" required />
            </div>
            <div>
                <p class="label">{{ getText("temperature-thresholds") }}</p>
                <TemperatureLine :thresholds="initThresholds" @valueChanged="valueChangeHandler" :revertTs="revertTs"></TemperatureLine>
            </div>
            <button v-if="!newFlag" class="more-options" @click="toggles.moreOptions = !toggles.moreOptions">
                <span>{{ getText("more-options") }}</span>
                <i :class="toggles.moreOptions ? 'dexcon-arrow-up' : 'dexcon-arrow-down'"></i>
            </button>
            <div v-if="!newFlag && toggles.moreOptions" class="label-group">
                <p class="label">{{ getText("delete-cool-obj") }}</p>
                <button class="box outline iss-red" @click="cueDeleteIntent">
                    <i class="dexcon-trash"></i>
                    <span>{{ getText("delete") }}</span>
                </button>
            </div>
            <div class="button-wrapper">
                <button v-if="!newFlag && valueChanged" class="outline box" @click="revertHandler">{{ getText("revert") }}</button>
                <button v-else-if="newFlag" @click="closePopup" class="outline box">{{ getText("cancel") }}</button>
                <button v-if="newFlag" class="box" @click="tryCreateClass" :disabled="!isComplete">{{ getText("create") }}</button>
                <button v-else class="box" :disabled="!valueChanged" @click="tryUpdateValues">{{ getText("save") }}</button>
            </div>
        </PopupWindow>
        <PopupWindow v-if="modal.name == 'delete-intent'" :coverWindow="true" @closePopup="closeModalPopup">
            <h3>{{ getText("delete-class-prefix") }}'{{ innerData.name }}'{{ getText("delete-class-suffix") }}?</h3>
            <div class="button-wrapper">
                <button class="box outline iss-red" @click="tryDeleteClass">
                    <i class="dexcon-trash"></i>
                    <span>{{ getText("delete") }}</span>
                </button>
                <button class="box" @click="closeModalPopup">{{ getText("cancel") }}</button>
            </div>
        </PopupWindow>
    </div>
</template>

<script>
    import PopupWindow from "@/components/default/PopupWindow.vue"
    import TemperatureLine from "@/components/TemperatureLine2.vue"
    import axios from "axios"
    import { mapGetters, mapActions } from "vuex"

    export default {
        name: "CoolingObjectTypeDetail",
        props: ["data"],
        components: {
            PopupWindow,
            TemperatureLine
        },
        data() {
            return {
                initData: Object.assign({}, this.data),
                innerData: Object.assign({}, this.data),
                newFlag: false,
                valueChanged: false,
                toggles: {
                    moreOptions: false
                },
                modal: {
                    name: null
                },
                revertTs: null,
                className: null
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("temp", {
                getTempFloat: "getConvertedTemperatureAsFloat"
            }),
            name: function () {
                return this.innerData.name;
            },
            initThresholds: function () {
                if (this.data.upperLimit == null) {
                    return {
                        cMax: null,
                        max: null,
                        min: null,
                        cMin: null
                    };
                }
                return {
                    cMax: this.data.upperLimit,
                    max: this.data.normalUpperLimit,
                    min: this.data.normalLowerLimit,
                    cMin: this.data.lowerLimit
                };
            },
            isComplete: function () {
                let checkComplete = (sum, obj) => {
                    if (obj[0].toLowerCase().indexOf("limit") > -1) {
                        return isNaN(obj[1]) || obj[1] == null ? sum + 1 : sum;
                    } else {
                        return obj[1] == "" || obj[1] == null ? sum + 1 : sum; 
                    }
                };
                return Object.entries(this.innerData).reduce(checkComplete, 0) > 0 ? false : true;
            }
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            tryCreateClass: function () {
                if (!this.validateEntry()) return false;
                this.replaceLoader({ loader: true });
                return axios.post("/api/coolingobjecttype", this.sanitizedCreateForm()).then(res => {
                    if (res.data.requestSuccess) {
                        this.closePopup();
                        this.$emit("reloadParent");
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    alert("Failed to update thresholds: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                })
            },
            tryUpdateValues: function () {
                if (!this.validateEntry()) return false;
                this.replaceLoader({ loader: true });
                return axios.post("/api/setcoolingobjecttypethreshold", this.sanitizedUpdateForm()).then(res => {
                    if (res.data.requestSuccess) {
                        if (window.localStorage.getItem("unit") != 'celcius') {
                            this.$emit("reload");
                        } else {
                            this.$emit("reloadParent");
                        }
                        this.initData = Object.assign({}, this.innerData);
                        this.valueChanged = false;
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    alert("Failed to update thresholds: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            tryDeleteClass: function () {
                this.replaceLoader({ loader: true });
                return axios.delete(`/api/CoolingObjectType/${this.innerData.id}`).then((res) => {
                    if (res.data.requestSuccess) {
                        this.closePopup();
                        this.$emit("reloadParent");
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    this.closeModalPopup();
                    alert("Failed to delete class: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            closePopup: function () {
                this.$emit("closePopup");
            },
            closeModalPopup: function () {
                this.modal = { name: null };
            },
            valueChangeHandler: function (val) {
                val.map((obj, ix) => {
                    switch (ix) {
                        case 0:
                            this.innerData.upperLimit = Number.parseFloat(obj.lowerBound);
                            break;
                        case 1:
                            this.innerData.normalUpperLimit = Number.parseFloat(obj.lowerBound);
                            break;
                        case 2:
                            this.innerData.normalLowerLimit = Number.parseFloat(obj.lowerBound);
                            break;
                        case 3:
                            this.innerData.lowerLimit = Number.parseFloat(obj.lowerBound);
                            break;
                    }
                });
            },
            revertHandler: function () {
                this.revertTs = new Date().getTime();
                if (this.newFlag) this.data.name = null;
            },
            validateEntry: function () {
                try {
                    if (this.innerData.lowerLimit >= this.innerData.normalLowerLimit) {
                        throw ["Min. Lower Temp.", "Lower Temp."];
                    } else if (this.innerData.normalLowerLimit >= this.innerData.normalUpperLimit) {
                        throw ["Lower Temp.", "Upper Temp."];
                    } else if (this.innerData.normalUpperLimit >= this.innerData.upperLimit) {
                        throw ["Upper Temp.", "Max. Upper Temp"];
                    }
                } catch (e) {
                    alert(e[0] + " must have a value which is less than " + e[1] + ", please adjust your values before proceeding.");
                    return false;
                }
                return true;
            },
            sanitizedUpdateForm: function () {
                let form = {
                    id: this.innerData.id,
                    lowerLimit: Number.parseFloat(this.innerData.lowerLimit),
                    normalLowerLimit: Number.parseFloat(this.innerData.normalLowerLimit),
                    normalUpperLimit: Number.parseFloat(this.innerData.normalUpperLimit),
                    upperLimit: Number.parseFloat(this.innerData.upperLimit)
                };
                const lims = ["upperLimit", "lowerLimit", "normalUpperLimit", "normalLowerLimit"];
                lims.map(o => { form[o] = this.getTempFloat(form[o], window.localStorage.getItem("unit"), "celcius"); });
                return form;
            },
            sanitizedCreateForm: function () {
                let form = {
                    name: this.innerData.name,
                    lowerLimit: Number.parseFloat(this.innerData.lowerLimit),
                    normalLowerLimit: Number.parseFloat(this.innerData.normalLowerLimit),
                    normalUpperLimit: Number.parseFloat(this.innerData.normalUpperLimit),
                    upperLimit: Number.parseFloat(this.innerData.upperLimit)
                };
                const lims = ["upperLimit", "lowerLimit", "normalUpperLimit", "normalLowerLimit"];
                lims.map(o => { form[o] = this.getTempFloat(form[o], window.localStorage.getItem("unit"), "celcius"); });
                return form;
            },
            cueDeleteIntent: function () {
                this.modal = { name: "delete-intent" };
            }
        },
        watch: {
            innerData: {
                deep: true,
                handler() {
                    let checkForChanges = (sum, obj) => { return obj[1] != this.initData[obj[0]] ? sum + 1 : sum };
                    this.valueChanged = Object.entries(this.innerData).reduce(checkForChanges, 0) > 0;
                }
            }
        },
        mounted() {
            if (this.data.id == null) {
                this.newFlag = true;
            }
        }
    }
</script>

<style scoped>
    .column-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

        .column-wrapper > .grow {
            flex-grow: 1;
        }

    .temperature-line {
        margin-top: var(--margin-05);
        margin-bottom: var(--margin-075);
    }

    .more-options {
        margin-bottom: var(--margin-075);
    }

    input {
        width: 100%;
    }

    .info {
        font-size: var(--smaller);
        margin-top: 0;
    }
</style>