<template>
    <div>
        <PopupWindow @closePopup="closePopup" :coverWindow="true">
            <div class="label-group">
                <p class="label">{{ getText("cool-obj") }}</p>
                <h3>
                    <span>{{ data.name }}</span>
                    <span v-if="!data.enabled" class="tag disabled-obj">{{ getText("obj-disabled") }}</span>
                </h3>
            </div>
            <div class="ts">
                <span>{{ getText("last-update") }}: {{ ts }}</span>
                <button @click="tryLoadStatus(true)">
                    <i class="dexcon-reload"></i>
                    <span>{{ getText("reload") }}</span>
                </button>
            </div>
            <div class="column-wrapper">
                <div v-if="thresholds">
                    <p class="label">{{ getText("temperature-45") }}</p>
                    <TemperatureLine :type="'status'" :thresholds="thresholds" :values="tempLineVal"></TemperatureLine>
                </div>
                <div v-else>
                    <div class="label-group">
                        <p class="label">{{ getText("min-temp") }}</p>
                        <p v-html="getTemp(data.minTemp, 'celcius')"></p>
                    </div>
                    <div class="label-group">
                        <p class="label">{{ getText("max-temp") }}</p>
                        <p v-html="getTemp(data.maxTemp, 'celcius')"></p>
                    </div>
                </div>
                <div class="grow">
                    <div class="label-group">
                        <p class="label">{{ getText("cool-obj-type") }}</p>
                        <div v-if="hasClientRoles(['user', 'manager']) || hasClientRoles(['user', 'supermanager'])">
                            <select v-model="type.target">
                                <option v-for="coType in coTypeNames" :key="coType">{{ coType }}</option>
                            </select>
                            <div v-if="type.init != type.target">
                                <button class="dexcon-close iss-red" @click="typeChangeHandler(type.init)"></button>
                                <button class="dexcon-checkmark free-green" @click="tryChangeObjectType"></button>
                            </div>
                        </div>
                        <p v-else>{{ sensorClass }}</p>
                    </div>
                    <div class="label-group">
                        <p class="label">{{ getText("sensor-status") }}</p>
                        <p v-if="data.enabled" class="tag" :class="'sensor-' + sensorStatus">{{ getText(`sensor-status-${sensorStatus > 0 ? 1 : 0}`) }}</p>
                        <p v-else>-</p>
                    </div>
                    <div class="label-group">
                        <p class="label">{{ getText("temp-status") }}</p>
                        <p v-if="data.enabled" class="tag" :class="'temp-' + tempStatus">{{ getText(`temp-status-${tempStatus}`) }}</p>
                        <p v-else>-</p>
                    </div>
                    <div v-if="doorTimeToLastTs" class="label-group">
                        <p class="label">{{ getText("door-status") }}</p>
                        <p>
                            <span>{{ getText(`door-status-${doorStatus}`) }}</span> <span class="ts-small">{{ getText("door-time-to-last-ts-pre") }}{{ doorTimeToLastTs }}{{ getText("door-time-to-last-ts-suf") }}</span>
                        </p>
                    </div>
                    <div v-if="tririgaId" class="label-group tririga-id">
                        <p class="label">{{ getText("tririga-id") }}</p>
                        <kbd>{{ tririgaId }}</kbd>
                    </div>
                    <div v-if="hasClientRoles(['user', 'manager']) || hasClientRoles(['user', 'supermanager'])">
                        <button class="more-options" @click="toggles.moreOptions = !toggles.moreOptions">
                            <span>{{ getText("more-options") }}</span>
                            <i :class="toggles.moreOptions ? 'dexcon-arrow-up' : 'dexcon-arrow-down'"></i>
                        </button>
                        <div v-if="toggles.moreOptions" class="label-group">
                            <p class="label">{{ getText("co-status") }}</p>
                            <div>
                                <button v-if="!this.data.enabled" @click="tryReplaceObjectStatus" class="box">{{ getText("co-enable") }}</button>
                                <button v-if="this.data.enabled" @click="cueDeactivateIntent" class="box outline iss-red">{{ getText("co-disable") }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="chart24" class="label-group">
                <p class="label">{{ getText("temp-hist") }}</p>
                <div class="table-wrapper">
                    <div class="canvas-wrapper">
                        <canvas width="7" height="4" ref="chart24"></canvas>
                    </div>
                </div>
            </div>
            <div v-if="lastMeasures" class="label-group">
                <p class="label">{{ getText("last-readings") }}</p>
                <div class="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th>{{ getText("sensor-use-type") }}</th>
                                <th>{{ getText("device-uid") }}</th>
                                <th>{{ getText("last-reading") }}</th>
                                <th>{{ getText("timestamp") }} ({{ localTimeSuffix }})</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="measure in lastMeasures" :key="measure.sensorId">
                                <td>{{ getText(`use-type-${measure.deviceUseTypeID}`) }}</td>
                                <td><kbd>{{ measure.sensorId }}</kbd></td>
                                <td align="center">
                                    <span v-if="measure.value == null">-</span>
                                    <span v-else-if="measure.deviceUseTypeID == 2">{{ getText(`door-status-${measure.value}`) }}</span>
                                    <span v-else v-html="getTemp(measure.value, 'celcius')"></span>
                                </td>
                                <td align="center">{{ measure.timeStamp ? new Date(measure.timeStamp + "Z").toLocaleString() : "-" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </PopupWindow>
        <PopupWindow v-if="modal.name == 'deactivate-intent'" :coverWindow="true" @closePopup="closeWarningPopup">
            <h3>{{ getText("deactivate-co-pre") }}'{{ data.name }}'{{ getText("deactivate-co-suf") }}?</h3>
            <div class="button-wrapper">
                <button class="outline iss-red box" @click="tryReplaceObjectStatus">{{ getText("co-disable") }}</button>
                <button class="box" @click="closeWarningPopup">{{ getText("cancel") }}</button>
            </div>
        </PopupWindow>
    </div>
</template>

<script>
    import axios from "axios"
    import Chart from 'chart.js/auto';
    import "chartjs-adapter-luxon"
    import { DateTime } from "luxon"
    import Vue from "vue"
    import PopupWindow from "@/components/default/PopupWindow.vue"
    import TemperatureLine from "@/components/TemperatureLine2.vue"
    import { mapActions, mapGetters } from "vuex"

    export default {
        name: "CoolingObjectDetail",
        props: ["initData", "initTs", "coTypes"],
        components: {
            PopupWindow,
            TemperatureLine
        },
        data() {
            return {
                data: this.initData,
                ts: this.initTs,
                type: {
                    init: this.initData.type,
                    target: this.initData.type
                },
                toggles: {
                    moreOptions: false
                },
                chart24: null,
                chartGmt: false,
                modal: {
                    name: null
                },
                localTimeSuffix: DateTime.local().offsetNameShort
            }
        },
        computed: {
            ...mapGetters("keycloak", {
                hasClientRoles: "hasClientRoles"
            }),
            ...mapGetters("temp", {
                getTemp: "getConvertedTemperatureAsString",
                getTempFloat: "getConvertedTemperatureAsFloat",
                getUnitShortHand: "getUnitShortHand"
            }),
            ...mapGetters("lang", {
                getText: "getText"
            }),
            sensorStatus: function () {
                return this.data.sensorStatus;
            },
            tempStatus: function () {
                return this.data.tempStatus;
            },
            sensorClass: function () {
                return this.data.type;
            },
            tririgaId: function () {
                return this.data.tririgaID;
            },
            lastMeasures: function () {
                return this.data.lastMeasures;
            },
            doorStatus: function () {
                return this.data.lastDoorState;
            },
            doorTimeToLastTs: function () {
                if (!this.data.lastTimestamp) return null;
                let dur = DateTime.now().diff(DateTime.fromISO(this.data.lastTimestamp + "Z"), ["days", "hours", "minutes", "seconds"]);
                let s = "";
                if (dur.days > 0) {
                    s += `${dur.values.days} ${this.getText("days")}`;
                } else if (dur.values.hours > 0) {
                    s += `${dur.values.hours} ${this.getText("hours")}`;
                } else if (dur.values.minutes > 0) {
                    s += `${dur.values.minutes} ${this.getText("minutes")}`;
                } else if (dur.values.seconds > 0) {
                    s += `${dur.values.seconds} ${this.getText("seconds")}`;
                }
                return s;
            },
            thresholds: function () {
                if (!this.data.thresholds) return this.data.thresholds;
                let thresholdCopy = { ...this.data.thresholds };
                Object.keys(thresholdCopy).map(o => { thresholdCopy[o] = this.getTempFloat(thresholdCopy[o], 'celcius'); });
                return thresholdCopy;
            },
            coTypeNames: function () {
                return this.coTypes.map(obj => obj.name);
            },
            tempLineVal: function () {
                let durationInMin = 45;
                let takeLastEntries = 10;
                let lastDurationReadings = new Array();
                this.data.lastDayData.map(obj => {
                    obj.dailyDataEntries.slice(obj.dailyDataEntries.length - takeLastEntries, obj.dailyDataEntries.length).map(obj => {
                        if (DateTime.now().diff(DateTime.fromISO(obj.x), ["minutes"]).minutes <= durationInMin) {
                            lastDurationReadings.push(obj.y);
                        }
                    });
                });
                let getAvg = (sum, obj) => { return sum + obj; };
                return {
                    'min-temp': this.getTempFloat(this.data.minTemp, 'celcius'),
                    'max-temp': this.getTempFloat(this.data.maxTemp, 'celcius'),
                    'avg-temp': this.getTempFloat(this.data.lastDayData.length > 0 ? Math.round(lastDurationReadings.reduce(getAvg, 0) / lastDurationReadings.length * 100) / 100 : null, 'celcius')
                };
            }
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            tryLoadStatus: function (showLoader) {
                if (showLoader) this.replaceLoader({ loader: true });
                return axios.get(`/api/coolingobject/${this.data.id}`).then(res => {
                    if (res.data.requestSuccess) {
                        if (!res.data.result) return;
                        res.data.result.name = this.data.name;
                        res.data.result.type = this.data.type;
                        res.data.result.id = this.data.id;
                        res.data.result.lastMeasures.sort((a, b) => { return a.deviceUseTypeID > b.deviceUseTypeID ? 1 : -1 });
                        Vue.set(this, "data", res.data.result);
                        this.ts = new Date().toLocaleString();
                    } else {
                        throw (res.data.message);
                    }
                    this.tryLoadChart();
                }).catch(e => {
                    alert("Failed to load cooling object status: " + e);
                }).finally(() => {
                    if (showLoader) this.replaceLoader({ loader: false });
                });
            },
            tryLoadChart: function () {
                try {
                    let sh = this.getUnitShortHand;
                    if (this.chart24 == null) {
                        this.chart24 = new Chart(this.$refs.chart24.getContext("2d"), {
                            type: 'line',
                            options: {
                                animation: false,
                                plugins: {
                                    legend: {
                                        display: true
                                    },
                                    tooltip: {
                                        callbacks: {
                                            label: function (context) {
                                                return `${context.dataset.label}: ${context.raw.y}${sh}`;
                                            }
                                        }
                                    }
                                },
                                scales: {
                                    x: {
                                        title: {
                                            display: true,
                                            text: this.chartGmt ? "Time (GMT)" : `${DateTime.local().offsetNameLong} (GMT${new Date().getTimezoneOffset() / -60 > 0 ? "+" + new Date().getTimezoneOffset() / -60 : new Date().getTimezoneOffset() / -60}) `
                                        },
                                        type: "time",
                                        time: {
                                            tooltipFormat: this.chartGmt ? "F" : "F ZZZZ",
                                            unit: 'hour'
                                        },
                                    },
                                    y: {
                                        title: {
                                            display: true,
                                            text: this.getText("temperature")
                                        },
                                        ticks: {
                                            callback: function (value) {
                                                return `${value}${sh}`;
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    }
                    this.chart24.data.datasets = new Array();
                    // Hourly Reducer
                    let n = 25;
                    let avg1H = new Array(n).fill(null).map((obj, ix) => {
                        return {
                            temps: new Array(this.data.lastDayData.length).fill(new Array()),
                            hour: new Date(new Date(new Date(new Date().getTime() - ix * 3600000).setMinutes(0)).setSeconds(0)).setMilliseconds(0),
                            x: new Date(new Date(new Date(new Date().getTime() - ix * 3600000 + (this.chartGmt ? new Date().getTimezoneOffset() * 60000 : 0)).setMinutes(30)).setSeconds(0)),
                            y: ix
                        }
                    });
                    this.data.lastDayData.map((obj, ix) => {
                        obj.dailyDataEntries.map(obj => {
                            let x = avg1H.find(el => el.hour == new Date(new Date(new Date(obj.x + "Z").setMinutes(0)).setSeconds(0)).setMilliseconds(0));
                            if (x) {
                                x.temps[ix].push(obj.y);
                            }
                            return obj;
                        });
                        return obj;
                    });
                    let avgReducer = (sum, o) => { return o ? sum + Number.parseFloat(o) : sum; };
                    let avgReducerWithNull = (sum, o) => {
                        return !isNaN(o) && o != null ? { val: sum.val + Number.parseFloat(o), nullVal: sum.nullVal } : { val: sum.val, nullVal: sum.nullVal + 1 };
                    };
                    avg1H.map(obj => {
                        obj.temps = obj.temps.map(objj => {
                            objj = objj.reduce(avgReducer, 0) / objj.length
                            return objj;
                        });
                        var tempRes = obj.temps.reduce(avgReducerWithNull, { val: 0, nullVal: 0 });
                        obj.y = tempRes.nullVal == obj.temps.length ? null : this.getTempFloat(tempRes.val / obj.temps.length, 'celcius');
                        return obj;
                    });
                    this.chart24.data.datasets.push({
                        label: this.getText("hourly-average"),
                        borderColor: "rgba(232, 71, 84, 1)",
                        backgroundColor: "rgba(232, 71, 84, 1)",
                        pointRadius: 1,
                        borderWidth: 3,
                        data: avg1H.map(obj => {
                            if (obj.temps.length == 0) {
                                obj.y = null;
                            }
                            return obj;
                        })
                    });
                    // 24 H based on hourly
                    let dayAverageReducer = (sum, o) => {
                        return !isNaN(o.y) && o.y != null ? { val: sum.val + Number.parseFloat(o.y), nullVal: sum.nullVal } : { val: sum.val, nullVal: sum.nullVal + 1 };
                    };
                    let avg24HHourly = [
                        {
                            x: new Date(new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setMinutes(30)).setSeconds(0)).setMilliseconds(0) + (this.chartGmt ? new Date().getTimezoneOffset() * 60000 : 0),
                            y: 0
                        },
                        {
                            x: new Date(new Date(new Date().setMinutes(30)).setSeconds(0)).setMilliseconds(0) + (this.chartGmt ? new Date().getTimezoneOffset() * 60000 : 0),
                            y: 0
                        }
                    ];
                    let tempAvg = avg1H.reduce(dayAverageReducer, { val: 0, nullVal: 0 });
                    this.chart24.data.datasets.push({
                        label: this.getText("24-hour-average"),
                        borderColor: "rgba(3, 0, 55, 1)",
                        backgroundColor: "rgba(3, 0, 55, 1)",
                        pointRadius: 1,
                        borderWidth: 3,
                        data: avg24HHourly.map(obj => {
                            obj.y = tempAvg.nullVal == avg1H.length ? null : Math.round(tempAvg.val / (avg1H.length - tempAvg.nullVal) * 100) / 100;
                            return obj;
                        })
                    });
                    // Sensor raw data
                    let colors = ["rgb(21, 58, 199)", "rgb(0, 132, 255)", "rgb(22, 228, 180)", "rgb(248, 213, 71)", "rgb(184, 186, 188)"];
                    this.data.lastDayData.map((obj, ix) => {
                        this.chart24.data.datasets.push({
                            label: obj.sensorId,
                            borderColor: colors[ix % colors.length],
                            backgroundColor: colors[ix % colors.length],
                            pointRadius: 1,
                            borderWidth: 1,
                            data: obj.dailyDataEntries.map(obj => { this.chartGmt ? null : obj.x += "Z"; const objCopy = { ...obj };  objCopy.y = this.getTempFloat(objCopy.y, 'celcius'); return objCopy; })
                        });
                        return obj;
                    });
                    this.chart24.update();
                } catch (e) {
                    console.log(e);
                    if (e.name != "TypeError") alert("Failed to load chart: " + e);
                }
            },
            tryReplaceObjectStatus: function () {
                this.replaceLoader({ loader: true });
                return axios.put(`/api/coolingobject/${this.data.id}/status/${!this.data.enabled}`).then(res => {
                    if (res.data.requestSuccess) {
                        this.tryLoadStatus(true);
                        this.closeWarningPopup();
                       this.$emit("reload");
                    } else {
                        throw res.data.message;
                    }
                }).catch(e => {
                    alert("Failed to activate/deactivate cooling object:" + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            tryChangeObjectType: function () {
                this.replaceLoader({ loader: true });
                return axios.post("/api/setCoolingObjectType", {
                    id: this.data.id,
                    typeId: this.coTypes.find(e => e.name == this.type.target).id
                }).then(res => {
                    if (res.data.requestSuccess) {
                        this.tryLoadStatus(true);
                        this.$emit("reload");
                        this.type.init = this.type.target;
                    } else {
                        throw res.data.message;
                    }
                }).catch(e => {
                    alert("Failed to change object type: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            closePopup: function () {
                this.$emit("closePopup");
            },
            copyTririgaId: function () {
                navigator.clipboard.writeText(this.tririgaId);
            },
            cueDeactivateIntent: function () {
                this.modal = { name: "deactivate-intent" };
            },
            typeChangeHandler: function (data) {
                this.type.target = data;
            },
            closeWarningPopup: function () {
                this.modal = { name: null };
            }
        },
        mounted() {
            Chart.defaults.font.family = "Noto Sans";
            let query = Object.assign({}, this.$route.query);
            query.coid = this.data.id;
            this.$router.replace({ query }).catch(e => {
                if (e.name != "NavigationDuplicated") throw e;
            });
            this.tryLoadStatus(true);
        }
    }
</script>

<style scoped>
    .column-wrapper {
        margin-bottom: 0;
        margin-top: var(--margin-05);
        margin-left: calc(-1 * var(--margin-075));
        width: calc(100% + 2 * var(--margin-075));
        display: flex;
        flex-wrap: wrap;
    }

    .column-wrapper + div {
        margin-top: var(--margin-075);
    }

        .column-wrapper > div {
            padding: 0 var(--margin-075);
            padding-top: var(--margin-05);
        }

        .column-wrapper > .grow {
            flex-grow: 1;
        }

        .canvas-wrapper {
            max-width: 100%;
        }

        .ts-small,
        .ts {
            font-size: var(--smaller);
        }

        .ts > * {
            vertical-align: middle;
        }

        .ts > button {
            margin-left: var(--margin-025);
        }

        .ts > button > span {
            margin-left: var(--margin-0125) !important;
        }
/*
    .tag {
        color: var(--stay-white);
        background: var(--iss-grey);
        border-radius: var(--margin-025);
        padding: var(--margin-0125) var(--margin-025);
        text-transform: uppercase;
        font-weight: var(--bolder);
        display: inline-block;
        font-size: var(--small);
        text-align: center;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .temp-0,
    .sensor-0 {
        background: var(--free-green);
    }

    .temp-1 {
        background: var(--idle-orange);
    }

    .temp-2,
    [class*="sensor-"]:not(.sensor-0) {
        background: var(--iss-red);
    }*/

    .disabled-obj {
        margin-left: var(--margin-025);
    }

    .tririga-id input {
        font-size: var(--smaller);
    }

    .tririga-id input {
        width: calc(22 * var(--smaller));
        max-width: 80%;
        display: inline-block;
    }

    select + div,
    .tririga-id input + button {
        display: inline-block;
        margin-left: var(--margin-025);
    }

    .more-options {
        font-size: var(--smaller);
    }

    .more-options:not(:last-child) {
        margin-bottom: var(--margin-075);
    }

    .more-options > [class*="dexcon-arrow-"] {
        margin-left: var(--margin-0125) !important;
    }

    table {
        overflow: hidden;
    }

    .temperature-line {
        margin-top: var(--margin-05);
    }
</style>