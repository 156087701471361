<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['viewer'])">
            <h2>{{ getText("account") }}</h2>
            <h3>{{ getText("app-settings") }}</h3>
            <div class="label-group">
                <p class="label">{{ getText("units") }}</p>
                <div class="label-wrapper">
                    <label>
                        <input type="radio" name="units" value="celcius" v-model="unit" />
                        <span v-html="getText('celcius-label')"></span>
                    </label>
                    <label>
                        <input type="radio" name="units" value="fahrenheit" v-model="unit" />
                        <span v-html="getText('fahrenheit-label')"></span>
                    </label>
                </div>
            </div>
            <p class="label">{{ getText("email-alerts") }}</p>
            <div class="label-group">
                <SubscribeToAlerts :userId="userId"></SubscribeToAlerts>
            </div>
            <h3>{{ getText("personal-detail") }}</h3>
            <div class="label-group">
                <p class="label">{{ getText("name") }}</p>
                <p>{{ getToken.name }}</p>
            </div>
            <div class="label-group">
                <p class="label">{{ getText("email") }}</p>
                <p>{{ getToken.email }}</p>
            </div>
            <div class="label-group">
                <p class="label">{{ getText("account-type") }}</p>
                <p>{{ accessLevel }}</p>
            </div>
            <div class="label-group">
                <p class="label">{{ getText("account-management") }}</p>
                <a class="dex-button box outline" :href="`${getKeycloak.refreshTokenParsed.iss}/account/#/`">
                    <span>{{ getText("manage-account") }}</span>
                    <i class="dexcon-chevron-right"></i>
                </a>
            </div>
        </div>
        <StandardRedirect v-else :targetRoute="'/'"></StandardRedirect>
    </div>
</template>

<script>
    import axios from "axios"
    import StandardRedirect from "@/components/default/StandardRedirect.vue"
    import SubscribeToAlerts from "@/components/user-management/SubscribeToAlerts.vue"
    import { mapActions, mapGetters } from "vuex"

    export default {
        name: "Account",
        components: {
            StandardRedirect,
            SubscribeToAlerts
        },
        data() {
            return {
                unit: null,
                userId: null
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                hasClientRoles: "hasClientRoles",
                getKeycloak: "getKeycloak",
                getToken: "getToken",
                getStatus: "getStatus"
            }),
            accessLevel: function () {
                let levelsHighToLow = ["SuperManager", "Manager", "User", "Viewer"];
                let getHighestLevel = (highest, obj) => { return highest == "" ? this.getToken.client_roles.includes(obj) ? obj : highest : highest; };
                return levelsHighToLow.reduce(getHighestLevel, "");
            }
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            ...mapActions("temp", {
                replaceUnit: "replaceUnit"
            }),
            tryGetUserId: function () {
                this.replaceLoader({ loader: true });
                return axios.get("/api/user/id").then(res => {
                    if (res.data.requestSuccess) {
                        this.userId = res.data.result;
                    } else {
                        throw res.data.message;
                    }
                }).catch(e => {
                    alert("Failed to get user ID: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            inferUnit: function () {
                var unit = window.localStorage.getItem("unit");
                if (unit) this.unit = unit;
            },
            init: function () {
                if (!this.hasClientRoles(['viewer'])) return;
                this.tryGetUserId();
                this.inferUnit();
            }
        },
        watch: {
            getStatus: function () {
                this.init();
            },
            unit: function () {
                this.replaceUnit({ unit: this.unit });
            }
        },
        mounted() {
            this.init();
        }
    }
</script>