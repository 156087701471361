<template>
    <li class="nav-item" ref="navItem" @click="expandDropdown">
        <a>
            <span v-html="itemLabel"></span>
            <i class="dexcon-arrow-down"></i>
        </a>
        <div class="dropdown" ref="dropdown">
            <button class="back" @click="shrinkDropdown">
                <i class="dexcon-chevron-left-thin"></i><span>{{ getText("back") }}</span>
            </button>
            <slot>
                <!--This component expects one or more <kbd>DexHeaderDropdownGroup</kbd> components in the slot.-->
            </slot>
        </div>
    </li>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "DexHeaderNavItemExpandable",
        props: ["itemLabel"],
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            })
        },
        methods: {
            shrinkDropdown: function (ev) {
                ev.stopPropagation();
                if (this.$refs.navItem) this.$refs.navItem.classList.remove("expanded");
            },
            expandDropdown: function () {
                if (this.$refs.navItem) this.$refs.navItem.classList.add("expanded");
            },
            toggleDropdown: function () {
                if (this.$refs.navItem == undefined) return;
                if (this.$refs.navItem.classList.contains("expanded")) {
                    this.shrinkDropdown();
                } else {
                    this.expandDropdown();
                }
            },
            shiftOverflowingDropdownIn: function () {
                if (
                    this.$refs.dropdown.getBoundingClientRect().left <
                    document
                        .querySelectorAll("header>ul>li:first-child")[0]
                        .getBoundingClientRect().left
                ) {
                    this.$refs.dropdown.style.setProperty(
                        "left",
                        `calc(50% + ${document
                            .querySelectorAll("header>ul>li:first-child")[0]
                            .getBoundingClientRect().left -
                        this.$refs.dropdown.getBoundingClientRect().left
                        }px)`
                    );
                } else if (
                    this.$refs.dropdown.getBoundingClientRect().right >
                    document
                        .querySelectorAll("header>ul>li:last-child")[0]
                        .getBoundingClientRect().right
                ) {
                    this.$refs.dropdown.style.setProperty(
                        "left",
                        `calc(50% - ${this.$refs.dropdown.getBoundingClientRect().right -
                        document
                            .querySelectorAll("header>ul>li:last-child")[0]
                            .getBoundingClientRect().right
                        }px)`
                    );
                }
            },
        },
        mounted() {
            window.addEventListener("resize", () => {
                if (this.$refs.dropdown) this.$refs.dropdown.classList.remove("expanded");
            });
            this.$refs.navItem.addEventListener("mouseover", () => {
                if (document.querySelector("header.collapsed")) return;
                if (this.$refs.navItem) this.$refs.navItem.classList.add("expanded");
                this.shiftOverflowingDropdownIn();
            });
            this.$refs.navItem.addEventListener("mouseout", () => {
                if (document.querySelector("header.collapsed")) return;
                if (this.$refs.navItem) this.$refs.navItem.classList.remove("expanded");
                if (this.$refs.dropdown) this.$refs.dropdown.style.setProperty("left", "50%");
            });
        },
    };
</script>

<style scoped>
    .dexcon-arrow-down {
        margin-left: 5px;
        line-height: 95%;
    }

    header.collapsed .nav-item .dexcon-arrow-down {
        float: right;
    }

        header.collapsed .nav-item .dexcon-arrow-down::before {
            content: "\e91c";
        }

    header.collapsed .nav-item::after {
        content: none;
    }
</style>