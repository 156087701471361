<template>
    <li class="nav-item" @click="closeHeaderIfExpanded">
        <router-link v-if="to" :to="to">
            <slot>
                <!--This component expects an item label as a string in this slot.-->
            </slot>
        </router-link>
        <slot v-else>
            <!--This component expects an item label as a string in this slot.-->
        </slot>
    </li>
</template>

<script>
    export default {
        name: "DexHeaderNavItemExpandable",
        props: ["to"],
        methods: {
            closeHeaderIfExpanded: function (ev) {
                ev.stopPropagation();
                if (document.querySelectorAll("header")[0] == undefined) return;
                document.querySelectorAll("header")[0].classList.remove("expanded");
                if (document.querySelectorAll("header .expanded")[0] == undefined) return;
                document
                    .querySelectorAll("header .expanded")[0]
                    .classList.remove("expanded");
            },
        },
    };
</script>

<style>
    /* The styles for .nav-item are declared in DexHeader.vue as they are shared by DexHeaderNavItem.vue and DexHeaderNavItemExpandable.vue */
</style>