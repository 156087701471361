<template>
    <div>
        <UnauthorizedRedirect v-if="getStatus && getProfile" :targetRoute="targetRoute"></UnauthorizedRedirect>
        <LoginRedirect v-else-if="getStatus"></LoginRedirect>
    </div>
</template>

<script>
    import LoginRedirect from "@/components/default/LoginRedirect.vue"
    import UnauthorizedRedirect from "@/components/default/UnauthorizedRedirect.vue"
    import { mapGetters } from "vuex"
    export default {
        name: "StandardRedirect",
        props: ["targetRoute"],
        components: {
            LoginRedirect,
            UnauthorizedRedirect
        },
        computed: {
            ...mapGetters("keycloak", {
                getStatus: "getStatus",
                getProfile: "getProfile"
            })
        }
    }
</script>