<template>
    <div>
        <p v-if="ts" class="last-update">{{ getText("last-update") }}: {{ ts }}</p>
        <p v-if="model.states.length == 0" style="margin-top: var(--margin-1);">{{ getText(`table-status-${tableStatus}`) }}</p>
        <SpatialModelGrouping v-else v-for="state in model.states" :key="state.stateName" :data="state">
            <SpatialModelGrouping v-for="city in state.cities" :key="city.cityName" :data="city">
                <SpatialModelGrouping v-for="building in city.buildings" :key="building.buildingName" :data="building" :lastNest="true">
                    <SpatialModelItem v-for="coolingObject in building.floors" :key="coolingObject.id" :data="coolingObject" @click.native="viewObject(coolingObject)">
                    </SpatialModelItem>
                </SpatialModelGrouping>
            </SpatialModelGrouping>
        </SpatialModelGrouping>
    </div>
</template>

<script>
    import axios from "axios"
    import Vue from "vue"
    import { mapActions, mapGetters } from "vuex"
    import SpatialModelGrouping from "@/components/status/SpatialModelGrouping.vue"
    import SpatialModelItem from "@/components/status/SpatialModelItem.vue"

    export default {
        name: "StatusSpatialModel",
        props: ["reloadTs", "sortLogic"],
        components: {
            SpatialModelGrouping,
            SpatialModelItem
        },
        data() {
            return {
                ts: null,
                model: {
                    states: []
                },
                tableStatus: 0,
                spatialModelPath: { states: "stateName", cities: "cityName", buildings: "buildingName", floors: "floorName", rooms: "roomName", coolingObjects: "name" },
                spatialModelPathForNameSort: { states: "stateName", cities: "cityName", buildings: "buildingName", floors: "name" }
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            })
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            tryLoadModel: function () {
                this.tableStatus = 0;
                this.replaceLoader({ loader: true });
                //this.model = {};
                return axios.get("/api/GetAllCoolingObjectStatusNested").then(res => {
                    if (res.data.requestSuccess) {
                        Vue.set(this, "model", res.data.result);
                        this.ts = new Date().toLocaleString();
                        this.traverseForAlerts(this.model);
                        this.bringObjectsUnderBuilding(this.model);
                        this.tableStatus = 1;
                    } else {
                        throw res.data.message;
                    }
                }).catch(e => {
                    alert("Failed to load status of cooling objects: " + e);
                    this.tableStatus = 2;
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            bringObjectsUnderBuilding: function (node) {
                let childArray = null;
                Object.keys(this.spatialModelPath).map(o => {
                    if (Object.keys(node).indexOf(o) > -1) childArray = o;
                    return;
                });
                if (childArray == "floors" || childArray == "rooms" || childArray == "coolingObjects") {
                    let arr = node[childArray].map(o => { let x = this.bringObjectsUnderBuilding(o); return x ? x : null; });
                    let temp = []
                    arr.map(o => {
                        if (Array.isArray(o)) {
                            temp = temp.concat(o);
                        } else {
                            temp.push(o);
                        }
                    });
                    if (childArray == "floors") node.floors = temp;
                    return temp;
                } else if (!childArray) {
                    return node;
                } else {
                    node[childArray].map(o => { this.bringObjectsUnderBuilding(o); });
                }
            },
            traverseForAlerts: function (node) {
                let childArray = null;
                Object.keys(this.spatialModelPath).map(o => {
                    if (Object.keys(node).indexOf(o) > -1) childArray = o;
                    return;
                });
                let criticalTemp = 0;
                let criticalSensor = 0;
                let totalCo = 0;
                if (!childArray) {
                    node.criticalTemp = !node.enabled ? 0 : node.tempStatus > 1 ? 1 : 0;
                    node.criticalSensor = !node.enabled ? 0 : node.sensorStatus > 0 ? 1 : 0;
                    return {
                        criticalTemp: node.criticalTemp,
                        criticalSensor: node.criticalSensor,
                        totalCo: !node.enabled ? 0 : 1
                    }
                } else {
                    node[childArray].map(o => {
                        let stati = this.traverseForAlerts(o);
                        if (!stati) return;
                        criticalTemp += isNaN(stati.criticalTemp) ? 0 : stati.criticalTemp;
                        criticalSensor += isNaN(stati.criticalSensor) ? 0 : stati.criticalSensor;
                        totalCo += stati.totalCo;
                        return o;
                    });
                }
                node.criticalTemp = criticalTemp;
                node.criticalSensor = criticalSensor;
                node.totalCo = totalCo;
                return {
                    criticalTemp: criticalTemp,
                    criticalSensor: criticalSensor,
                    totalCo: totalCo
                };
            },
            viewObject: function (data) {
                data.tableTs = this.ts;
                this.$emit("viewObject", data);
            },
            sortBy: function (node, sortLogic) {
                this.sortRecurse(node, sortLogic);
            },
            sortRecurse: function (node, sortLogic) {
                let childArray = null;
                Object.keys(this.spatialModelPath).map(o => {
                    if (Object.keys(node).indexOf(o) > -1) childArray = o;
                    return;
                });
                if (!childArray) return;
                let logic = null;
                const nameAz = (a, b) => {
                    let deactivateRes = !a.enabled - !b.enabled;
                    if (deactivateRes == 0) {
                        return a[this.spatialModelPathForNameSort[childArray]] < b[this.spatialModelPathForNameSort[childArray]] ? -1 : 1;
                    }
                    return deactivateRes;
                };
                const warnAz = (a, b) => {
                    let deactivateRes = !a.enabled - !b.enabled;
                    if (deactivateRes == 0) {
                        let totalWarningA = a.criticalTemp + a.criticalSensor;
                        let totalWarningB = b.criticalTemp + b.criticalSensor;
                        let warningSort = totalWarningB - totalWarningA;
                        if (warningSort == 0) {
                            return a[this.spatialModelPathForNameSort[childArray]] < b[this.spatialModelPathForNameSort[childArray]] ? -1 : 1;
                        }
                        return warningSort;
                    }
                    return deactivateRes;
                }
                switch (sortLogic) {
                    case 'name-az':
                        logic = nameAz;
                        break;
                    case 'warn-az':
                        logic = warnAz;
                        break;
                    default:
                        return;
                }
                node[childArray] = node[childArray].sort(logic);
                node[childArray].map(o => { this.sortRecurse(o, sortLogic); });
                return;
            },
            inferTargetObject: function (node) {
                let res = this.inferTargetObjectRecurse(node);
                if (this.$route.query.coid != undefined && !res) {
                    alert("The cooling object ID provided was either invalid, or you may not have the necessary permission to access the cooling object.");
                }
                let query = Object.assign({}, this.$route.query);
                delete query.coid;
                this.$router.replace({ query }).catch(e => {
                    if (e.name != "NavigationDuplicated") throw e;
                });
            },
            inferTargetObjectRecurse: function (node) {
                if (this.$route.query.coid) {
                    let childArray = null;
                    Object.keys(this.spatialModelPath).map(o => {
                        if (Object.keys(node).indexOf(o) > -1) childArray = o;
                        return;
                    });
                    if (childArray) {
                        let atLeastOneTrue = (sum, o) => { return sum || o; };
                        return node[childArray].map(o => { return this.inferTargetObjectRecurse(o); }).reduce(atLeastOneTrue, false);
                    } else {
                        if (node.id == this.$route.query.coid) {
                            this.viewObject(node);
                            return true;
                        }
                    }
                    return false;
                }
            },
            init: async function () {
                await this.tryLoadModel();
                this.sortBy(this.model, this.sortLogic);
                this.inferTargetObject(this.model);
            }
        },
        watch: {
            reloadTs: function () {
                this.init();
            },
            sortLogic: function () {
                this.sortBy(this.model, this.sortLogic);
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .last-update { 
        font-size: var(--smaller);
        margin-bottom: var(--margin-05);
    }
</style>