<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['manager'])">
            <transition name="slideRight">
                <Users v-if="subpage.name == 'users-by-role'"></Users>
            </transition>
            <transition name="slideLeft">
                <User v-if="subpage.name == 'user'"></User>
            </transition>
        </div>
        <StandardRedirect v-else :targetRoute="'/'"></StandardRedirect>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex"
    import Users from "@/components/user-management/Users.vue"
    import User from "@/components/user-management/User.vue"
    import StandardRedirect from "@/components/default/StandardRedirect.vue"

    export default {
        name: "TypeManagement",
        components: {
            Users,
            User,
            StandardRedirect
        },
        computed: {
            ...mapGetters("keycloak", {
                hasClientRoles: "hasClientRoles"
            }),
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("userManagement", {
                subpage: "getSubpage",
            })
        },
        methods: {
            ...mapActions("userManagement", {
                replaceSubpage: "replaceSubpage"
            })
        },
        mounted() {
            this.replaceSubpage({ subpage: { name: 'users-by-role', data: [] } });
        }
    }
</script>

<style scoped>
    .slideLeft-enter-active {
        animation: slide-left var(--quick);
    }

    .slideLeft-leave {
        animation: slide-left var(--quick) reverse;
    }

    @keyframes slide-left {
        0% {
            opacity: 0;
            transform: translate(5%, 0);
        }
        100% {
            opacity: 1;
            transform: translate(0);
        }
    }

    .slideRight-enter-active {
        animation: slide-right var(--quick);
    }

    .slideRight-leave {
        animation: slide-right var(--quick) reverse;
    }

    @keyframes slide-right {
        0% {
            opacity: 0;
            transform: translate(-5%, 0);
        }

        100% {
            opacity: 1;
            transform: translate(0);
        }
    }
</style>