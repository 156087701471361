<template>
    <div id="app">
        <div class="width-wrapper" :visible="kcInit">
            <DexHeader :appName="'Coolchain Monitoring'">
                <DexHeaderNavSection>
                    <DexHeaderNavItem :to="'/overview'" v-if="hasClientRoles(['viewer'])">{{ getText("status") }}</DexHeaderNavItem>
                    <DexHeaderNavItemExpandable :itemLabel="getText('management')" v-if="hasClientRoles(['manager'])">
                        <DexHeaderDropdownGroup :itemLabel="getText('cool-objs')">
                            <DexHeaderDropdownGroupItem :to="'/classes'">{{ getText("co-type-management") }}</DexHeaderDropdownGroupItem>
                        </DexHeaderDropdownGroup>
                        <DexHeaderDropdownGroup :itemLabel="getText('users')">
                            <DexHeaderDropdownGroupItem :to="'/users'">{{ getText("user-management") }}</DexHeaderDropdownGroupItem>
                            <DexHeaderDropdownGroupItem :to="'/roles'">{{ getText("role-management") }}</DexHeaderDropdownGroupItem>
                        </DexHeaderDropdownGroup>
                        <DexHeaderDropdownGroup :itemLabel="getText('systems')" v-if="hasClientRoles(['supermanager'])">
                            <DexHeaderDropdownGroupItem :to="'/interfaces'">{{ getText("interfaces") }}</DexHeaderDropdownGroupItem>
                        </DexHeaderDropdownGroup>
                    </DexHeaderNavItemExpandable>
                </DexHeaderNavSection>
                <DexHeaderNavSection>
                    <DexHeaderUserItemExpandable :itemLabel="getLang.toUpperCase()">
                        <DexHeaderDropdownGroup :itemLabel="getText('set-lang')">
                            <DexHeaderDropdownGroupItem :src="'flags/de.svg'" @click.native="setActiveLang({ lang: 'de' })">
                                {{ getText("lang-de") }}
                            </DexHeaderDropdownGroupItem>
                            <DexHeaderDropdownGroupItem :src="'flags/uk.svg'" @click.native="setActiveLang({ lang: 'en' })">
                                {{ getText("lang-en") }}
                            </DexHeaderDropdownGroupItem>
                        </DexHeaderDropdownGroup>
                    </DexHeaderUserItemExpandable>
                    <DexHeaderUserItem @click.native="tryLogin" v-if="!getProfile">
                        <a>{{ getText("login") }}</a>
                    </DexHeaderUserItem>
                    <DexHeaderUserItemExpandable :initials="getInitials" v-if="getProfile">
                        <DexHeaderDropdownGroup :itemLabel="`${getText('welcome-back')} ${getProfile.firstName}`">
                            <DexHeaderDropdownGroupItem :to="'/account'">
                                {{ getText("account") }}
                            </DexHeaderDropdownGroupItem>
                            <DexHeaderDropdownGroupItem @click.native="tryLogout">
                                {{ getText("logout") }}
                            </DexHeaderDropdownGroupItem>
                        </DexHeaderDropdownGroup>
                    </DexHeaderUserItemExpandable>
                    <DexHeaderUserItem>
                        <a href="https://www.de.issworld.com/de-de/services/services,-die-wir-anbieten/workplace" target="_blank" style="font-size: 0;">
                            <img src="./assets/images/dfm-logo.png" />
                        </a>
                    </DexHeaderUserItem>
                </DexHeaderNavSection>
            </DexHeader>
            <router-view />
            <DexFooter>
                <li>
                    <router-link to="/impressum">{{ getText("imprint") }}</router-link>
                </li>
                <li>
                    <a href="https://www.de.issworld.com/de-de/datenschutz"
                       target="_blank">{{ getText("privacy") }}</a>
                </li>
                <li>
                    <a href="https://www.de.issworld.com/de-de/karriere/ihre-karriere-bei-iss/karriere"
                       target="_blank">{{ getText("careers") }}</a>
                </li>
                <li>
                    <a href="https://www.de.issworld.com/de-de/ueber-iss/people-make-places/ueber-iss"
                       target="_blank">{{ getText("about-iss") }}</a>
                </li>
                <li>
                    <a href="https://www.de.issworld.com/de-de/ueber-iss/kontakt/kontakt-zu-iss"
                       target="_blank">{{ getText("contact") }}</a>
                </li>
            </DexFooter>
        </div>
        <div class="loading-gif width-wrapper">
            <img src="loading.gif" v-if="!kcInit" />
        </div>
        <PopupWindow v-if="loader" :canBeToggled="false" :coverWindow="true" :clearBackground="true" :loader="true"></PopupWindow>
    </div>
</template>

<script>
    import DexHeader from "@/components/default/header/DexHeader.vue";
    import DexHeaderNavSection from "@/components/default/header/DexHeaderNavSection.vue";
    import DexHeaderNavItem from "@/components/default/header/DexHeaderNavItem.vue";
    import DexHeaderNavItemExpandable from "@/components/default/header/DexHeaderNavItemExpandable.vue";
    import DexHeaderUserItem from "@/components/default/header/DexHeaderUserItem.vue";
    import DexHeaderUserItemExpandable from "@/components/default/header/DexHeaderUserItemExpandable.vue";
    import DexHeaderDropdownGroup from "@/components/default/header/DexHeaderDropdownGroup.vue";
    import DexHeaderDropdownGroupItem from "@/components/default/header/DexHeaderDropdownGroupItem.vue";
    import DexFooter from "@/components/default/DexFooter.vue";
    import PopupWindow from "@/components/default/PopupWindow.vue";
    import { mapGetters, mapActions, mapMutations } from "vuex";
    import * as dexlib from "@/assets/js/dexlib.js";

    export default {
        name: "app",
        components: {
            DexHeader,
            DexHeaderNavSection,
            DexHeaderNavItem,
            DexHeaderNavItemExpandable,
            DexHeaderUserItem,
            DexHeaderUserItemExpandable,
            DexHeaderDropdownGroup,
            DexHeaderDropdownGroupItem,
            DexFooter,
            PopupWindow
        },
        data() {
            return {
                kcInit: false
            }
        },
        computed: {
            ...mapGetters("keycloak", {
                getKeycloak: "getKeycloak",
                getProfile: "getProfile",
                getInitials: "getInitials",
                hasClientRoles: "hasClientRoles",
                getCheckSso: "getSsoCheck"
            }),
            ...mapGetters("lang", {
                getText: "getText",
                getLang: "getActiveLang",
            }),
            ...mapGetters("loader", {
                loader: "getLoader"
            })
        },
        methods: {
            ...mapActions("keycloak", {
                tryLogin: "tryLogin",
                tryLogout: "tryLogout",
                initKeycloak: "initKeycloak"
            }),
            ...mapActions("temp", {
                replaceUnit: "replaceUnit"
            }),
            ...mapMutations("lang", {
                setActiveLang: "setActiveLang",
            }),
            contentMinHeightHandler: function () {
                if (!document.querySelector("#app>div>div") || !document.querySelector("header") || !document.querySelector("footer")) return;
                document.querySelector("#app>div>div").style.setProperty(
                    "min-height",
                    `calc(100vh - ${window.getComputedStyle(document.querySelector("header")).height
                    } - ${window.getComputedStyle(document.querySelector("footer")).height
                    })`
                );
            }
        },
        async mounted() {
            await this.initKeycloak();
            this.kcInit = true;
            if (dexlib.getParam("lang")) {
                this.setActiveLang({ lang: dexlib.getParam("lang") });
            } else {
                this.setActiveLang({ lang: navigator.language.slice(0, 2) });
            }
            this.contentMinHeightHandler();
            window.addEventListener("resize", () => {
                this.contentMinHeightHandler();
            });
            let unit = window.localStorage.getItem("unit");
            if (!unit) unit = "celcius";
            this.replaceUnit({ unit: unit });
        },
        updated() {
            this.contentMinHeightHandler();
        }
    };
</script>

<style scoped>
    .loading-gif {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100vw;
        height: 100vh;
        z-index: -1;
        display: flex;
    }

        .loading-gif > img {
            margin: auto;
        }

    #app>div:first-child {
        opacity: 0;
    }

    [visible] {
        animation: forwards ease var(--quick) dissolve-in;
    }

    @keyframes dissolve-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
</style>

<style>
    table {
        position: relative;
    }

    table > tbody > tr {
        animation: float-up var(--quick) ease forwards;
    }

    @keyframes float-up {
        0% {
            opacity: 0;
            transform: translate(0, 5px);
        }

        100% {
            opacity: 1;
            transform: translate(0);
        }
    }

    [sortable] {
        cursor: pointer;
    }
</style>