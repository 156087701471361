import Vue from 'vue';

export default ({
    namespaced: true,
    state: {
        subpage: {
            name: null,
            data: null
        },
        targetRole: null,
        roleList: [],
        userList: [],
        tableTs: null
    },
    getters: {
        getSubpage: function (state) {
            return state.subpage;
        },
        getTargetRole: function (state) {
            return state.targetRole;
        },
        getUserList: function (state) {
            return state.userList;
        },
        getTableTs: function (state) {
            return state.tableTs;
        },
        getRoleList: function (state) {
            return state.roleList;
        }
    },
    mutations: {
        setSubpage: function (state, payload) {
            if (payload.subpage == undefined) return;
            Vue.set(state, "subpage", payload.subpage);
        },
        setTargetRole: function (state, payload) {
            if (payload.role == undefined) return;
            Vue.set(state, "targetRole", payload.role);
        },
        setUserList: function (state, payload) {
            if (payload.userList == undefined) return;
            Vue.set(state, "userList", payload.userList);
        },
        setTableTs: function (state, payload) {
            if (payload.tableTs == undefined) payload.tableTs = null;
            Vue.set(state, "tableTs", payload.tableTs);
        }, 
        setRoleList: function (state, payload) {
            if (payload.roleList == undefined) payload.roleList = null;
            Vue.set(state, "roleList", payload.roleList);
        }
    },
    actions: {
        replaceSubpage: function (context, payload) {
            context.commit("setSubpage", payload);
        },
        replaceTargetRole: function (context, payload) {
            context.commit("setTargetRole", payload);
        },
        replaceUserList: function (context, payload) {
            context.commit("setUserList", payload);
        },
        replaceTableTs: function (context, payload) {
            context.commit("setTableTs", payload);
        },
        replaceRoleList: function (context, payload) {
            context.commit("setRoleList", payload);
        },
        storeUserKeySort: function (context, payload) {
            if (payload.key == undefined) return;
            var key = payload.key;
            context.state.userList.sort((a, b) => {
                return a[key] > b[key] ? 1 : -1;
            });
        }
    }
})