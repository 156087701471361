import Vue from 'vue';

export default ({
    namespaced: true,
    state: {
        subpage: {
            name: null,
            data: null,
            roleList: []
        }
    },
    getters: {
        getSubpage: function (state) {
            return state.subpage;
        },
        getRoleList: function (state) {
            return state.roleList;
        }
    },
    mutations: {
        setSubpage: function (state, payload) {
            if (payload.subpage == undefined) return;
            Vue.set(state, "subpage", payload.subpage);
        },
        setRoleList: function (state, payload) {
            if (payload.roleList == undefined) payload.roleList = null;
            Vue.set(state, "roleList", payload.roleList);
        }
    },
    actions: {
        replaceSubpage: function (context, payload) {
            context.commit("setSubpage", payload);
        },
        replaceRoleList: function (context, payload) {
            context.commit("setRoleList", payload);
        }
    }
})