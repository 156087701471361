<template>
    <div>
        <h2>{{ getText("user-roles") }}</h2>
        <div class="table-options">
            <span class="label">{{ getText("role") }}</span>
            <select v-model="targetRole">
                <option v-for="role in availableRoles" :key="role">{{ role }}</option>
            </select>
            <span class="label">{{ getText("options") }}</span>
            <button @click="newUserPopup">
                <i class="dexcon-plus"></i>
                <span>{{ getText("add-user") }}</span>
            </button>
            <button @click="tryReload">
                <i class="dexcon-reload"></i>
                <span>{{ getText("reload") }}</span>
            </button>
        </div>
        <div class="table-wrapper">
            <table style="height: 50vh;">
                <thead>
                    <tr>
                        <th @click="keySort(users, 'firstName')" :title="getText('sort-az')" sortable>{{ getText("first-name") }}</th>
                        <th @click="keySort(users, 'lastName')" :title="getText('sort-az')" sortable>{{ getText("last-name") }}</th>
                        <th @click="keySort(users, 'email')" :title="getText('sort-az')" sortable>{{ getText("email") }}</th>
                        <th>{{ getText("role") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="users.length == 0">
                        <td colspan="4" align="center">
                            {{ getText(`table-status-${tableStatus}`) }}
                        </td>
                    </tr>
                    <tr v-for="user in users" :key="user.id">
                        <td>{{ user.firstName }}</td>
                        <td>{{ user.lastName }}</td>
                        <td>{{ user.email }}</td>
                        <td>
                            <button v-if="getProfile.email != user.email" @click="confirmDelete(user)" class="box outline iss-red" >
                                <i class="dexcon-close"></i>
                                <span>{{ getText("revoke") }}</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p v-if="tableTs" class="table-detail">{{ getText("last-update") }}: {{ tableTs }}</p>
        <PopupWindow v-if="popup.name == 'confirm-delete'" :coverWindow="true" :hideCloseButton="true" @closePopup="closePopup">
            <h3>{{ getText("confirm-revoke-prefix") }}'{{ targetRole }}'{{ getText("confirm-revoke-suffix") }}?</h3>
            <div class="button-wrapper">
                <button class="outline box iss-red" @click="tryGrantRole(false)">
                    <i class="dexcon-close"></i>
                    <span>{{ getText("revoke") }}</span>
                </button>
                <button @click="closePopup" class="box">{{ getText("cancel") }}</button>
            </div>
        </PopupWindow>
        <PopupWindow v-if="popup.name == 'new-user'" @closePopup="closePopup" :coverWindow="true">
            <h3>{{ getText("assign-role-prefix") }}'{{ targetRole }}'{{ getText("assign-role-suffix") }}</h3>
            <div v-if="tableStatus2 == 0 || availableUsers.length > 0">
                <div class="table-wrapper" align="center">
                    <table class="clickable" style="height: 30vh;">
                        <thead>
                            <tr>
                                <th >({{ markedUsers.length }})</th>
                                <th @click="keySort(availableUsers, 'firstName')" :title="getText('sort-az')" sortable>{{ getText("first-name") }}</th>
                                <th @click="keySort(availableUsers, 'lastName')" :title="getText('sort-az')" sortable>{{ getText("last-name") }}</th>
                                <th @click="keySort(availableUsers, 'email')" :title="getText('sort-az')" sortable>{{ getText("email") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="availableUsers.length == 0">
                                <td colspan="7" align="center">
                                    {{ getText(`table-status-${tableStatus2}`) }}
                                </td>
                            </tr>
                            <tr v-for="user in availableUsers" :key="user.id" @click="markUser(user.id)" align="left">
                                <td>
                                    <input type="checkbox" :checked="markedUsers.indexOf(user.id) > -1" />
                                </td>
                                <td>{{ user.firstName }}</td>
                                <td>{{ user.lastName }}</td>
                                <td>{{ user.email }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="button-wrapper">
                    <button @click="closePopup" class="box outline">{{ getText("cancel") }}</button>
                    <button class="box" @click="tryGrantRole(true)" :disabled="markedUsers.length == 0">{{ getText("assign") }}</button>
                </div>
            </div>
            <div v-else>
                <p>{{ getText("already-assigned-prefix") }}'{{ targetRole }}'{{ getText("already-assigned-suffix") }}.</p>
                <div class="button-wrapper">
                    <button @click="closePopup" class="box">{{ getText("okay") }}</button>
                </div>
            </div>
        </PopupWindow>
    </div>
</template>

<script>
    import axios from "axios"
    import { mapActions, mapGetters } from "vuex"
    import PopupWindow from "@/components/default/PopupWindow.vue"

    export default {
        name: "UsersByRolePage",
        components: {
            PopupWindow
        },
        data() {
            return {
                targetRole: null,
                availableRoles: [],
                availableUsers: [],
                users: [],
                markedUsers: [],
                popup: {
                    name: null,
                    data: null
                },
                tableStatus: 0,
                tableStatus2: 0,
                tableTs: null
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile"
            })
        },
        methods: {
            ...mapActions("roleManagement", {
                replaceSubpage: "replaceSubpage"
            }),
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            tryGetAvailableRoles: function () {
                this.replaceLoader({ loader: true });
                this.availableRoles = [];
                return axios.get("/api/roles").then(res => {
                    if (res.data.requestSuccess) {
                        this.availableRoles = res.data.result;
                        this.targetRole = res.data.result[0];
                    } else {
                        throw res.data.message;
                    }
                }).catch(e => {
                    alert("Failed to load available roles: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                })
            },
            tryLoadUsers: function () {
                if (!this.targetRole) return;
                this.replaceLoader({ loader: true });
                this.users = [];
                this.tableStatus = 0;
                this.tableTs = null;
                return axios.get(`/api/UsersByRole/${this.targetRole}`).then(res => {
                    if (res.data.requestSuccess) {
                        this.users = res.data.result;
                        this.tableStatus = 1;
                        this.tableTs = new Date().toLocaleString();
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    alert("Failed to load users: " + e);
                    this.tableStatus = 2;
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            tryLoadAvailableUsers: function () {
                this.replaceLoader({ loader: true });
                this.availableUsers = [];
                this.tableStatus2 = 0;
                return axios.get("api/GetAllUsers").then(res => {
                    if (res.data.requestSuccess) {
                        this.availableUsers = res.data.result.filter(user => !this.users.find(u => u.id == user.id));
                        this.tableStatus2 = 1;
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    alert("Failed to load users: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            tryGrantRole: function (intent) {
                if (intent == null || intent == undefined) return;
                this.replaceLoader({ loader: true });
                let ids = intent ? this.markedUsers : [this.popup.data.id];
                axios.post("api/UsersByRole", {
                    role: this.targetRole,
                    userIds: ids,
                    grantRole: intent
                }).then(res => {
                    if (res.data.requestSuccess) {
                        this.closePopup();
                        this.tryLoadUsers();
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    if (!intent) this.closePopup();
                    alert("Failed to update user role: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                })
            },
            confirmDelete: function (data) {
                this.popup = { name: "confirm-delete", data: data };
            },
            closePopup: function () {
                this.popup = { name: null, data: null };
            },
            newUserPopup: function () {
                this.tryLoadAvailableUsers();
                this.markedUsers = [];
                this.popup = { name: "new-user", data: null };
            },
            markUser: function (userId) {
                var i = this.markedUsers.indexOf(userId);
                if (i < 0) {
                    this.markedUsers.push(userId);
                } else {
                    this.markedUsers.splice(i, 1);
                }
            },
            init: function () {
                if (!this.getProfile) return;
                this.tryGetAvailableRoles();
                if (this.availableUsers.length == 0) {
                    this.tryLoadUsers();
                }
            },
            tryReload: function () {
                this.tryGetAvailableRoles();
                this.tryLoadUsers();
            },
            keySort: function (arr, key) {
                arr.sort((a, b) => {
                    return a[key] > b[key] ? 1 : -1;
                });
            }
        },
        watch: {
            targetRole: function () {
                this.tryLoadUsers();
            },
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped >
    button.revoke {
        color: var(--iss-red) !important;
        border-color: var(--iss-red) !important;
    }

    input[type=checkbox] {
        pointer-events: none;
    }

    tr:hover > td > input[type=checkbox]::before {
        border-color: var(--azure-blue) !important;
    }
</style>