<template>
    <div>
        <button class="nav box outline" @click="replaceSubpage({ subpage: { name: 'users-by-role', data: undefined }})">
            <i class="dexcon-chevron-left-thin"></i>
            <span>{{ getText("back") }}</span>
        </button>
        <p class="label">{{ getText("user") }}</p>
        <h2>{{ subpage.data.firstName }} {{ subpage.data.lastName }}</h2>
        <p class="label">{{ getText("email") }}</p>
        <p>{{ subpage.data.email }} </p>
        <div class="label-group">
            <h3>{{ getText("email-alerts") }}</h3>
            <SubscribeToAlerts :userId="subpage.data.id"></SubscribeToAlerts>
        </div>
        <!--<h3>{{ getText("email-alerts") }}</h3>-->
        <!--<div class="label-wrapper">
        <div v-if="alertStatus == 0 || alerts.length == 0">
            <p >{{ getText(`table-status-${alertStatus}`) }}</p>
        </div>
        <label v-for="alert in alerts" :key="alert.alertId">
            <input type="checkbox" :checked="alert.subscribed" @click="trySubscribe(alert)"/>
            <span>{{ alert.alertName }}</span>
        </label>
    </div>-->
        <h3>{{ getText("cool-objs") }}</h3>
        <div class="table-options">
            <span class="label">{{ getText("options") }}</span>
            <button @click="addObject">
                <i class="dexcon-plus"></i>
                <span>{{ getText("add-obj") }}</span>
            </button>
            <button @click="tryLoadObjects">
                <i class="dexcon-reload"></i>
                <span>{{ getText("reload") }}</span>
            </button>
        </div>
        <div class="table-wrapper">
            <table style="height: 50vh;">
                <thead>
                    <tr>
                        <th @click="keySort(objects, 'city')" :title="getText('sort-az')" sortable>{{ getText("city") }}</th>
                        <th @click="keySort(objects, 'building')" :title="getText('sort-az')" sortable>{{ getText("building") }}</th>
                        <th @click="keySort(objects, 'floor')" :title="getText('sort-az')" sortable>{{ getText("floor") }}</th>
                        <th @click="keySort(objects, 'room')" :title="getText('sort-az')" sortable>{{ getText("room") }}</th>
                        <th @click="keySort(objects, 'type')" :title="getText('sort-az')" sortable>{{ getText("cool-obj-type") }}</th>
                        <th @click="keySort(objects, 'name')" :title="getText('sort-az')" sortable>{{ getText("cool-obj") }}</th>
                        <th>{{ getText("access") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="objects.length == 0">
                        <td colspan="7" align="center">
                            {{ getText(`table-status-${tableStatus}`) }}
                        </td>
                    </tr>
                    <tr v-for="object in objects" :key="object.id">
                        <td>{{ object.city }}</td>
                        <td>{{ object.building }}</td>
                        <td>{{ object.floor }}</td>
                        <td>{{ object.room }}</td>
                        <td>{{ object.type }}</td>
                        <td>{{ object.name }}</td>
                        <td>
                            <button @click="confirmRevoke(object)" class="box outline iss-red">
                                <i class="dexcon-close"></i>
                                <span>{{ getText("revoke") }}</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p v-if="tableTs" class="table-detail">{{ getText("last-update") }}: {{ tableTs}} </p>
        <PopupWindow v-if="popup.name == 'add'" :coverWindow="true" @closePopup="closePopup">
            <h3>{{ getText("assign-obj-access") }}</h3>
            <div v-if="tableStatus2 == 0 || availableObjects.length > 0">
                <div class="table-wrapper" align="center">
                    <table class="clickable" style="height: 30vh;">
                        <thead>
                            <tr>
                                <th>({{ markedObjects.length }})</th>
                                <th @click="keySort(availableObjects, 'city')" :title="getText('sort-az')" sortable>{{ getText("city") }}</th>
                                <th @click="keySort(availableObjects, 'building')" :title="getText('sort-az')" sortable>{{ getText("building") }}</th>
                                <th @click="keySort(availableObjects, 'floor')" :title="getText('sort-az')" sortable>{{ getText("floor") }}</th>
                                <th @click="keySort(availableObjects, 'room')" :title="getText('sort-az')" sortable>{{ getText("room") }}</th>
                                <th @click="keySort(availableObjects, 'type')" :title="getText('sort-az')" sortable>{{ getText("cool-obj-type") }}</th>
                                <th @click="keySort(availableObjects, 'name')" :title="getText('sort-az')" sortable>{{ getText("cool-obj") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="availableObjects.length == 0">
                                <td colspan="7" align="center">
                                    {{ getText(`table-status-${tableStatus2}`) }}
                                </td>
                            </tr>
                            <tr v-for="object in availableObjects" :key="object.id" @click="markObject(object.id)" align="left">
                                <td>
                                    <input type="checkbox" :checked="markedObjects.indexOf(object.id) > -1" />
                                </td>
                                <td>{{ object.city }}</td>
                                <td>{{ object.building }}</td>
                                <td>{{ object.floor }}</td>
                                <td>{{ object.room }}</td>
                                <td>{{ object.type }}</td>
                                <td>{{ object.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="button-wrapper">
                    <button class="box outline" @click="closePopup">{{ getText("cancel") }}</button>
                    <button class="box" @click="tryAssignObjects(true)" :disabled="markedObjects.length == 0">{{ getText("assign") }}</button>
                </div>
            </div>
            <div v-else>
                <p>{{ getText("access-has-been-given") }}.</p>
                <div class="button-wrapper">
                    <button @click="closePopup" class="box">{{ getText("okay") }}</button>
                </div>
            </div>
        </PopupWindow>
        <PopupWindow v-if="popup.name == 'confirm-revoke'" :coverWindow="true" :hideCloseButton="true" @click="closePopup" @closePopup="closePopup">
            <h3>{{ getText("confirm-revoke-obj-prefix") }}'{{ popup.data.name }}'{{ getText("confirm-revoke-obj-suffix") }}?</h3>
            <div class="button-wrapper">
                <button class="box outline iss-red" @click="tryAssignObjects(false)">
                    <i class="dexcon-close"></i>
                    <span>{{ getText("revoke") }}</span>
                </button>
                <button class="box" @click="closePopup">{{ getText("cancel") }}</button>
            </div>
        </PopupWindow>
    </div>
</template>

<script>
    import axios from "axios"
    import Vue from "vue"
    import { mapActions, mapGetters } from "vuex"
    import PopupWindow from "@/components/default/PopupWindow.vue"
    import SubscribeToAlerts from "@/components/user-management/SubscribeToAlerts.vue"

    export default {
        name: "UserPage",
        props: ["userData"],
        components: {
            PopupWindow,
            SubscribeToAlerts
        },
        data() {
            return {
                popup: {
                    name: null,
                    data: null
                },
                alerts: [],
                objects: [],
                availableObjects: [],
                markedObjects: [],
                tableStatus: 0,
                tableStatus2: 0,
                alertStatus: 0,
                tableTs: null
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("userManagement", {
                subpage: "getSubpage"
            })
        },
        methods: {
            ...mapActions("userManagement", {
                replaceSubpage: "replaceSubpage"
            }),
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            closePopup: function () {
                this.popup = { name: null, data: null };
            },
            tryLoadObjects: function () {
                this.replaceLoader({ loader: true });
                this.tableStatus = 0;
                return axios.get(`api/CoolingObjects/${this.subpage.data.id}`).then(res => {
                    if (res.data.requestSuccess) {
                        Vue.set(this, "objects", res.data.result);
                        this.tableStatus = 1;
                        this.tableTs = new Date().toLocaleString();
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    alert("Failed to load cooling objects: " + e);
                    this.tableStatus = 2;
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            tryLoadAvailableObjects: function () {
                this.replaceLoader({ loader: true });
                this.availableObjects = [];
                this.tableStatus2 = 0;
                return axios.get("api/GetAllCoolingObjectStatus").then(res => {
                    if (res.data.requestSuccess) {
                        this.availableObjects = res.data.result.filter(object => !this.objects.find(o => o.id == object.id));
                        this.tableStatus2 = 1;
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    alert("Failed to load cooling objects: " + e);
                    this.tableStatus2 = 2;
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            addObject: function () {
                this.markedObjects = [];
                this.popup = { name: "add", data: null };
                this.tryLoadAvailableObjects();
            },
            markObject: function (objectId) {
                var i = this.markedObjects.indexOf(objectId);
                if (i < 0) {
                    this.markedObjects.push(objectId);
                } else {
                    this.markedObjects.splice(i, 1);
                }
            },
            tryAssignObjects: function (intent) {
                if (intent == null || intent == undefined) return;
                this.replaceLoader({ loader: true });
                let ids = intent ? this.markedObjects : [ this.popup.data.id ];
                return axios.post("api/CoolingObjectsByUser ", {
                    userId: this.subpage.data.id,
                    coolingObjectList: ids,
                    grantAccess: intent
                }).then(res => {
                    if (res.data.requestSuccess) {
                        this.tryLoadObjects();
                        this.closePopup();
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    if (!intent) this.closePopup();
                    alert("Failed to assign cooling object: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            confirmRevoke: function (data) {
                this.popup = { name: "confirm-revoke", data: data };
            },
            keySort: function (arr, key) {
                arr.sort((a, b) => {
                    return a[key] > b[key] ? 1 : -1;
                });
            },
            init: function () {
                this.tryLoadObjects();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    tr:hover > td > input[type=checkbox]::before {
        border-color: var(--azure-blue) !important;
    }

    [sortable] {
        cursor: pointer;
    }
</style>