<template>
    <div>
        <h3>Redirecting...</h3>
    </div>
</template>

<script>
import { mapActions } from "vuex";

    export default {
        name: "LoginRedirect",
        methods: {
            ...mapActions("keycloak", {
                tryLogin: "tryLogin"
            })
        },
        mounted() {
            this.tryLogin();
        }
    }
</script>