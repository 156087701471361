<template>
  <li class="user-item" @click="closeHeaderIfExpanded">
    <router-link v-if="to" :to="to">
      <slot>
        <!--This component expects an item label as a string in this slot or an <kbd>a</kbd>.-->
      </slot>
    </router-link>
    <slot v-else>
      <!--This component expects an <kbd>img</kbd> or <kbd>a</kbd>.-->
    </slot>
  </li>
</template>

<script>
export default {
  name: "DexHeaderUserItem",
  props: ["to"],
  methods: {
    closeHeaderIfExpanded: function (ev) {
      ev.stopPropagation();
      document.querySelectorAll("header")[0].classList.remove("expanded");
      document
        .querySelectorAll("header .expanded")[0]
        .classList.remove("expanded");
    },
  },
};
</script>

<style>
/* The styles for .user-item are declared in DexHeader.vue as they are shared by DexHeaderUserItem.vue and DexHeaderUserItemExpandable.vue */

header.collapsed .user-item>a>img {
    padding-left: var(--margin-075);
}

.user-item>a>img {
    margin-top: -2px;
    height: 45px;
}
</style>