<template>
    <footer>
        <ul>
            <slot>
            </slot>
            <li>&copy; {{ new Date().getFullYear() }}</li>
            <li class="tagline">PE<span @click="openCredits">O</span>PLE MAKE PLACES</li>
        </ul>
        <PopupWindow v-if="popup.name == 'credits'" @closePopup="closePopup" :coverWindow="true">
            <h3>You've found our Easter egg!</h3>
            <p>We hope you've enjoyed using our product so far.</p>
            <p>This product was made possible by the collective effort of the DEX Germany team:</p>
            <ul>
                <li>Anees Khan</li>
                <li>Bastian Oe&szlig;</li>
                <li>Daniel Wittkuhn</li>
                <li>Ingo H&uuml;ttemeyer</li>
                <li>Matius Chairani</li>
                <li>Shraddha Upadhya</li>
                <li>Tobias K&uuml;rschner</li>
            </ul>
            <p>On behalf of all of us, thank you for using our product!</p>
        </PopupWindow>
    </footer>
</template>

<script>
    import PopupWindow from "@/components/default/PopupWindow.vue"

    export default {
        name: "DexFooter",
        components: {
            PopupWindow
        },
        data() {
            return {
                popup: {
                    name: null
                }
            }
        },
        methods: {
            openCredits: function () {
                this.popup = { name: "credits" };
            },
            closePopup: function () {
                this.popup = { name: null };
            }
        }
    }
</script>

<style scoped>
    footer {
        background: var(--iss-blue);
        text-align: center;
        padding: var(--header-margin) var(--side-margin-2);
    }

    @media only screen and (min-width: 576px) {
        footer {
            text-align: left;
        }
    }

    footer > ul {
        display: flex;
        flex-wrap: wrap;
    }

        footer > ul > * {
            color: var(--stay-white);
            display: inline-block;
            font-size: var(--smaller);
            margin: 0;
            margin-right: var(--margin-075);
            opacity: 0.6;
            text-decoration: none !important;
            transition: opacity ease var(--quick);
        }

            footer > ul > *:last-child {
                margin-right: 0;
            }

    footer a {
        color: inherit;
        font-weight: inherit;
    }

    @media (hover:hover) and (pointer:fine) {
        footer > ul > li:hover {
            opacity: 0.8;
        }
    }

    footer > ul > li:active {
        opacity: 1;
    }

    .tagline {
        float: right;
        color: var(--stay-white);
        flex-grow: 1;
        text-align: right;
        font-weight: var(--bolder);
    }
</style>