import Vue from 'vue'
import Vuex from 'vuex'
import languageModule from './default/language.js'
import keycloakModule from './default/keycloak.js'
import loaderModule from './default/loader.js'
import temperatureModule from './temperature.js'
import userManagementModule from './user-management.js'
import roleManagementModule from './role-management.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        lang: languageModule,
        keycloak: keycloakModule,
        loader: loaderModule,
        userManagement: userManagementModule,
        roleManagement: roleManagementModule,
        temp: temperatureModule
    }
})