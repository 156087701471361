<template>
    <button class="spatial-model-item">
        <div class="flex">
            <div class="header">
                <span>{{name}}</span>
            </div>
            <div v-if="enabled">
                <i v-if="tempStatus > 1" class="dexcon-temperature-3"></i>
                <i v-if="sensorStatus > 0" class="dexcon-warn"></i>
            </div>
            <div v-if="!enabled">
                <span class="tag">{{ getText("obj-disabled") }}</span>
            </div>
        </div>
        <div class="flex">
            <div>
                <span>Min. Temp.</span>
                <span v-html="getTemp(minTemp, 'celcius')"></span>
            </div>
            <div>
                <span>Max. Temp.</span>
                <span v-html="getTemp(maxTemp, 'celcius')"></span>
            </div>
            <div>
                <span>Temp.</span>
                <span v-if="enabled" class="tag" :class="`temp-${tempStatus}`">{{ getText(`temp-status-${tempStatus}`) }}</span>
                <span v-else>-</span>
            </div>
            <div>
                <span>Sensors</span>
                <span v-if="enabled" class="tag" :class="`sensor-${sensorStatus}`">{{ getText(`sensor-status-${sensorStatus > 0 ? 1 : 0}`) }}</span>
                <span v-else>-</span>
            </div>
            <div>
                <span>Class</span>
                <span>{{ coClass }}</span>
            </div>
        </div>
    </button>
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        name: "SpatialModelItem",
        props: ["data"],
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("temp", {
                getTemp: "getConvertedTemperatureAsString"
            }),
            name: function () {
                return this.data.name;
            },
            coClass: function () {
                return this.data.type;
            },
            minTemp: function () {
                return this.data.minTemp ? this.data.minTemp : "-";
            },
            maxTemp: function () {
                return this.data.maxTemp ? this.data.maxTemp : "-";
            },
            tempStatus: function () {
                return this.data.tempStatus;
            }, 
            sensorStatus: function () {
                return this.data.sensorStatus;
            },
            enabled: function () {
                return this.data.enabled;
            }
        }
    }
</script>

<style scoped>
    .spatial-model-item {
        display: block !important;
        padding: var(--margin-05) !important;
        margin-top: var(--margin-025);
        border: var(--iss-grey) 1px solid !important;
        width: 100%;
    }

        .spatial-model-item:first-child {
            margin-top: var(--margin-05);
        }

    .flex {
        display: flex;
        flex-wrap: wrap;
        margin-left: calc(-1 * var(--margin-05));
    }

    .flex:first-child {
        margin-bottom: var(--margin-025);
    }

        .flex > * {
            margin-left: var(--margin-05);
        }

        .flex:nth-child(2) > div > span:first-child {
            font-size: var(--small);
            margin-right: var(--margin-025);
            text-transform: uppercase;
            font-weight: var(--bold);
            opacity: 0.8;
        }

    .flex>.header {
        flex-grow: 1;
    }

    .flex>.header>span:first-child {
        font-size: var(--larger);
        font-weight: var(--bolder);
        text-transform: none;
        margin-bottom: var(--margin-025) !important;
    }

    .flex>.header+div {
        color: var(--iss-red);
    }

    .flex>.header+div>i:not(:first-child) {
        margin-left: var(--margin-05);
    }

    .flex>.header>* {
        vertical-align: middle;
    }

    .tag {
        font-size: var(--small);
    }
</style>