<template>
    <div>
        <div v-if="alertStatus == 0 || alerts.length == 0">
            <p>{{ getText(`table-status-${alertStatus}`) }}</p>
        </div>
        <div v-else class="label-wrapper">
            <label v-for="alert in alerts" :key="alert.alertId">
                <input type="checkbox" :checked="alert.subscribed" @click="trySubscribe(alert)" :disabled="!canModify" />
                <span>{{ alert.alertName }}</span>
            </label>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import Vue from "vue"
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "SubscribeToAlerts",
        props: ["userId"],
        data() {
            return {
                alerts: [],
                alertStatus: 0
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                hasClientRoles: "hasClientRoles"
            }),
            canModify: function () {
                return this.hasClientRoles(["user"]);
            }
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            tryLoadAlerts: function () {
                this.replaceLoader({ loader: true });
                this.alerts = [];
                this.alertStatus = 0;
                return axios.get(`/api/MailAlerts/${this.userId}`).then(res => {
                    if (res.data.requestSuccess) {
                        this.alertStatus = 1;
                        this.alerts = res.data.result;
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    this.alertStatus = 2;
                    alert("Failed to load email alert subscription: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            trySubscribe: function (data) {
                this.replaceLoader({ loader: true });
                axios.post("/api/MailAlerts", {
                    userId: this.userId,
                    alertTypeId: data.alertId,
                    subscribeIntent: !data.subscribed
                }).then(res => {
                    if (res.data.requestSuccess) {
                        Vue.set(this.alerts.find(a => a.alertId == data.alertId), "subscribed", !data.subscribed);
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    alert("Failed to subscribe: " + e);
                    this.tryLoadAlerts();
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            init: function () {
                if (!this.userId) return;
                this.tryLoadAlerts();
            }
        },
        watch: {
            userId: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>