<template>
    <div class="temperature-line">
        <table v-if="thresholds && values">
            <tbody>
                <tr v-for="range in computedTempRanges" :key="range.name">
                    <td class="range">
                        <div v-if="range.name != 0" class="bound-dash"></div>
                        <div :class="'range-desc-' + range.status">
                            <p>
                                {{ getText(`temp-status-${range.status}`) }}
                            </p>
                            <p v-if="range.name == 0 && !isNaN(Number.parseFloat(range.lowerBound))">
                                &ge; {{ Math.ceil((Number.parseFloat(range.lowerBound)) * 100) / 100 }}{{ getUnitShortHand }}
                            </p>
                            <p v-else-if="range.name == 1 && !isNaN(Number.parseFloat(range.lowerBound)) && !isNaN(Number.parseFloat(computedTempRanges[0].lowerBound)) && Number.parseFloat(range.lowerBound) < Number.parseFloat(computedTempRanges[0].lowerBound)">
                                {{ Math.ceil((Number.parseFloat(range.lowerBound)) * 100) / 100  }}{{ getUnitShortHand }} &ndash; {{ Math.ceil((computedTempRanges[0].lowerBound - 0.01)* 100) / 100 }}{{ getUnitShortHand }}
                            </p>
                            <p v-else-if="range.name == 2 && !isNaN(Number.parseFloat(range.lowerBound)) && !isNaN(Number.parseFloat(computedTempRanges[1].lowerBound))  && Number.parseFloat(range.lowerBound) < Number.parseFloat(computedTempRanges[1].lowerBound)">
                                {{ Math.ceil((Number.parseFloat(range.lowerBound) + 0.01) * 100) / 100  }}{{ getUnitShortHand }} &ndash; {{ Math.ceil((computedTempRanges[1].lowerBound - 0.01)* 100) / 100 }}{{ getUnitShortHand }}
                            </p>
                            <p v-else-if="range.name == 3 && !isNaN(Number.parseFloat(range.lowerBound)) && !isNaN(Number.parseFloat(computedTempRanges[2].lowerBound)) && Number.parseFloat(range.lowerBound) < Number.parseFloat(computedTempRanges[2].lowerBound)">
                                {{ Math.ceil((Number.parseFloat(range.lowerBound) + 0.01) * 100) / 100  }}{{ getUnitShortHand }} &ndash; {{ Math.ceil((computedTempRanges[2].lowerBound) * 100) / 100 }}{{ getUnitShortHand }}
                            </p>
                            <p v-else-if="range.name == 4 && !isNaN(Number.parseFloat(computedTempRanges[3].lowerBound))">
                                &le; {{ Math.ceil((computedTempRanges[3].lowerBound) * 100) / 100 }}{{ getUnitShortHand }}
                            </p>
                            <p v-else class="inv">
                                <i class="dexcon-warn"></i>
                                <span>Invalid range</span>
                            </p>
                        </div>
                        <span v-if="range.lowerBound != null" class="bound">
                            {{ range.lowerBound }}{{ getUnitShortHand }}
                        </span>
                    </td>
                    <td>
                        <div v-for="val in range.values" :key="val.temp + new Date().getTime()" class="temp-val">
                            <span :class="'temp-label-' + range.status">{{ getText(val.name) }}</span>
                            <span>{{ val.temp }}{{ getUnitShortHand }}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <table v-else-if="thresholds" class="edit-temp">
            <tbody>
                <tr v-for="range in innerRanges" :key="range.name">
                    <td class="range">
                        <div :class="'range-desc-' + range.status">
                            <p>
                                {{ getText(`temp-status-${range.status}`) }}
                            </p>
                            <p v-if="range.name == 0 && !isNaN(Number.parseFloat(range.lowerBound))">
                                &ge; {{ Math.ceil((Number.parseFloat(range.lowerBound)) * 100) / 100 }}{{ getUnitShortHand }}
                            </p>
                            <p v-else-if="range.name == 1 && !isNaN(Number.parseFloat(range.lowerBound)) && !isNaN(Number.parseFloat(innerRanges[0].lowerBound)) && Number.parseFloat(range.lowerBound) < Number.parseFloat(innerRanges[0].lowerBound)">
                                {{ Math.ceil((Number.parseFloat(range.lowerBound)) * 100) / 100  }}{{ getUnitShortHand }} &ndash; {{ Math.ceil((innerRanges[0].lowerBound - 0.01)* 100) / 100 }}{{ getUnitShortHand }}
                            </p>
                            <p v-else-if="range.name == 2 && !isNaN(Number.parseFloat(range.lowerBound)) && !isNaN(Number.parseFloat(innerRanges[1].lowerBound))  && Number.parseFloat(range.lowerBound) < Number.parseFloat(innerRanges[1].lowerBound)">
                                {{ Math.ceil((Number.parseFloat(range.lowerBound) + 0.01) * 100) / 100  }}{{ getUnitShortHand }} &ndash; {{ Math.ceil((innerRanges[1].lowerBound - 0.01)* 100) / 100 }}{{ getUnitShortHand }}
                            </p>
                            <p v-else-if="range.name == 3 && !isNaN(Number.parseFloat(range.lowerBound)) && !isNaN(Number.parseFloat(innerRanges[2].lowerBound)) && Number.parseFloat(range.lowerBound) < Number.parseFloat(innerRanges[2].lowerBound)">
                                {{ Math.ceil((Number.parseFloat(range.lowerBound) + 0.01) * 100) / 100  }}{{ getUnitShortHand }} &ndash; {{ Math.ceil((innerRanges[2].lowerBound) * 100) / 100 }}{{ getUnitShortHand }}
                            </p>
                            <p v-else-if="range.name == 4 && !isNaN(Number.parseFloat(innerRanges[3].lowerBound))">
                                &le; {{ Math.ceil((innerRanges[3].lowerBound) * 100) / 100 }}{{ getUnitShortHand }}
                            </p>
                            <p v-else class="inv">
                                <i class="dexcon-warn"></i>
                                <span>{{ getText("invalid-range") }}</span>
                            </p>
                        </div>
                    </td>
                    <td class="value-cell">
                        <div v-if="range.name != 4" class="temp-val">
                            <p class="label">{{ getText(`temp-bound-${range.name}`) }}</p>
                            <input type="number" v-model="range.lowerBound" required step="0.01" />
                            <span>{{ getUnitShortHand }}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="info">{{ getText("not-to-scale") }}</p>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "TemperatureLine",
        props: ["thresholds", "values", "revertTs"],
        data() {
            return {
                innerThreshold: this.thresholds,
                initRanges: this.initTempRanges(),
                innerRanges: this.initTempRanges()
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("temp", {
                getTemp: "getConvertedTemperatureAsString",
                getTempFloat: "getConvertedTemperatureAsFloat",
                getUnitShortHand: "getUnitShortHand"
            }),
            computedTempRanges: function () {
                this.innerThreshold;
                let tempRanges = this.initTempRanges();
                Object.entries(this.values).map(obj => {
                    if (obj[1] == null) return null;
                    let targetRange = null;
                    if (obj[1] >= tempRanges[0].lowerBound) {
                        targetRange = tempRanges[0];
                    } else if (obj[1] >= tempRanges[1].lowerBound) {
                        targetRange = tempRanges[1];
                    } else if (obj[1] <= tempRanges[4].lowerBound) {
                        targetRange = tempRanges[4];
                    } else if (obj[1] <= tempRanges[3]) {
                        targetRange = tempRanges[3];
                    } else {
                        targetRange = tempRanges[2];
                    }
                    targetRange.values.push({ name: obj[0], temp: obj[1] });
                    return null;
                });
                tempRanges.map(obj => {
                    obj.values.sort((a, b) => Number.parseFloat(a.temp) > Number.parseFloat(b.temp) ? -1 : 1); // Sort descending
                    return obj;
                });
                return tempRanges;
            },
            classTempRanges: function () {
                return this.initTempRanges();
            }
        },
        methods: {
            initTempRanges: function () {
                return new Array(5).fill(null).map((obj, ix) => {
                    let lowerBound = null;
                    let rangeStatus = 2;
                    switch (ix) {
                        case 0:
                            lowerBound = this.thresholds.cMax;
                            rangeStatus = 2;
                            break;
                        case 1:
                            lowerBound = this.thresholds.max;
                            rangeStatus = 1;
                            break;
                        case 2:
                            lowerBound = this.thresholds.min;
                            rangeStatus = 0;
                            break;
                        case 3:
                            lowerBound = this.thresholds.cMin;
                            rangeStatus = 1;
                            break;
                        default:
                            rangeStatus = 2;
                            break;
                    }
                    return {
                        name: ix,
                        lowerBound: lowerBound,
                        status: rangeStatus,
                        values: new Array()
                    };
                });
            }
        },
        watch: {
            innerRanges: {
                deep: true,
                handler() {
                    this.$emit("valueChanged", this.innerRanges);
                }
            },
            revertTs: function () {
                this.initRanges = this.initTempRanges();
                this.innerRanges = this.initTempRanges();
            }
        }
    }
</script>

<style scoped>
    .temperature-line {
        margin: 0;
        display: inline-block;
    }

    table {
        overflow: visible;
        border: 0;
    }

        table tr {
            animation: none;
        }

        table td {
            background: none !important;
            position: relative;
            padding: 0;
        }

    .range {
        height: 100%;
    }



    [class*="range-desc-"] {
        text-align: center;
        margin: var(--margin-075) var(--margin-1);
        margin-left: 0;
        font-size: var(--smaller);
    }

    [class*="range-desc-"] > p:nth-child(2) {
        font-size: var(--smaller);
        color: var(--iss-blue);
    }

    .edit-temp [class*="range-desc-"] {
        margin: var(--margin-1);
    }

    [class*="range-desc-"]::after {
        content: " ";
        width: 5px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }

    .range-desc-0 {
        color: var(--free-green);
    }

        .temp-label-0,
        .range-desc-0::after {
            background: var(--free-green);
        }

    .range-desc-1 {
        color: var(--idle-orange);
    }

        .temp-label-1,
        .range-desc-1::after {
            background: var(--idle-orange);
        }

    .range-desc-2 {
        color: var(--iss-red);
    }

        .temp-label-2,
        .range-desc-2::after {
            background: var(--iss-red);
        }

    .bound {
        position: absolute;
        right: 15px;
        bottom: 0;
        font-size: var(--small);
        transform: translateY(50%);
        font-weight: var(--bolder);
        color: var(--iss-blue);
    }

    .bound-dash {
        position: absolute;
        top: 0;
        right: 0;
    }

        .bound-dash::after {
            content: " ";
            width: 15px;
            height: 2px;
            background: var(--iss-blue);
            position: absolute;
            top: 50%;
            right: -5px;
            z-index: 1;
        }

    .value-cell {
        width: calc(5 * var(--margin-1));
    }

    .temp-val {
        padding: var(--margin-025) var(--margin-05);
        padding-right: 0;
        position: relative;
    }

        .temp-val:first-child {
            margin-top: var(--margin-05);
        }

        .temp-val:last-child {
            margin-bottom: var(--margin-05);
        }

        .temp-val::after {
            content: " ";
            width: var(--small);
            height: var(--small);
            background: var(--stay-white);
            border: 2px var(--iss-blue) solid;
            border-radius: 50%;
            position: absolute;
            left: -2px;
            top: 50%;
            transform: translate(-50%, -50%);
        }

    .edit-temp .temp-val {
        margin: 0;
        position: relative;
        bottom: -50px;
        z-index: 1;
    }

    [class*="temp-label-"] {
        font-size: var(--small);
        color: var(--stay-white);
        border-radius: var(--margin-025);
        font-weight: var(--bolder);
        text-transform: uppercase;
        padding: var(--margin-0125) var(--margin-025);
        margin-right: var(--margin-025);
    }

    .temp-val > span:nth-child(2) {
        font-size: var(--larger);
        font-weight: var(--bolder);
        vertical-align: middle;
        color: var(--iss-blue);
    }

    .info {
        text-align: center;
        color: var(--iss-grey);
        font-size: var(--x-small);
        margin-top: var(--margin-025);
    }

    .inv {
        color: var(--iss-grey) !important;
        /*color: var(--iss-red) !important;*/
    }

    .dexcon-warn {
        margin-right: var(--margin-0125);
        font-size: 80%;
    }

    input {
        width: calc(5 * var(--medium));
        text-align: right;
    }

    [class*="range-desc-"] > p {
        margin: 0;
    }
</style>