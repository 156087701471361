<template>
  <div class="dropdown-group">
    <p v-if="itemLabel" v-html="itemLabel" class="group-label"></p>
    <ul class="group-wrapper">
      <slot>
        <!--This component expects one or more
        <kbd>DexHeaderDropdownGroupItem</kbd> or <kbd>DexHeaderDropdownGroupLanguageItem</kbd> components in this slot.-->
      </slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DexHeaderDropdownGroup",
  props: ["itemLabel"],
};
</script>

<style scoped>
.dropdown-group {
  margin-bottom: 10px;
  display: block;
}

.dropdown-group:last-child {
  margin-bottom: 0 !important;
}

.group-label {
  font-size: var(--small);
  text-transform: uppercase;
  color: var(--azure-blue);
  font-weight: var(--bolder);
  margin-bottom: var(--margin-025);
}

.group-wrapper {
  margin-left: calc(-1 * var(--margin-025));
  margin-top: calc(-1 * var(--margin-025));
}

.collapsed .expanded .dropdown .group-wrapper {
  margin-left: 0;
}

.collapsed .dropdown-group {
  margin-bottom: var(--margin-1);
}
</style>