<template>
    <div>
        <div class="table-wrapper">
            <table class="clickable" style="height: 50vh;">
                <thead>
                    <tr>
                        <th @click="keySort(objects, 'city')" :title="getText('sort-az')" sortable>{{ getText("city") }}</th>
                        <th @click="keySort(objects, 'building')" :title="getText('sort-az')" sortable>{{ getText("building") }}</th>
                        <th @click="keySort(objects, 'floor')" :title="getText('sort-az')" sortable>{{ getText("floor") }}</th>
                        <th @click="keySort(objects, 'room')" :title="getText('sort-az')" sortable>{{ getText("room") }}</th>
                        <th @click="keySort(objects, 'name')" :title="getText('sort-az')" sortable>{{ getText("cool-obj") }}</th>
                        <th @click="keySort(objects, 'minTemp')" :title="getText('sort-az')" sortable>{{ getText("min-temp") }}</th>
                        <th @click="keySort(objects, 'maxTemp')" :title="getText('sort-az')" sortable>{{ getText("max-temp") }}</th>
                        <th @click="keySort(objects, 'tempStatus')" :title="getText('sort-az')" sortable>{{ getText("temp-stat") }}</th>
                        <th @click="keySort(objects, 'sensorStatus')" :title="getText('sort-az')" sortable>{{ getText("sensor-stat") }}</th>
                        <th @click="keySort(objects, 'type')" :title="getText('sort-az')" sortable>{{ getText("cool-obj-type") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="objects.length == 0">
                        <td colspan="10" align="center">
                            {{ getText(`table-status-${tableStatus}`) }}
                        </td>
                    </tr>
                    <tr v-for="object in objects" :key="object.id" @click="viewObject(object)">
                        <td>{{ object.city }}</td>
                        <td>{{ object.building }}</td>
                        <td>{{ object.floor }}</td>
                        <td>{{ object.room }}</td>
                        <td>
                            <span class="status" :class="{ 'enabled': object.enabled }">{{ object.name }}</span>
                        </td>
                        <td v-if="object.minTemp" align="center">{{ object.minTemp }}&deg;C</td>
                        <td v-else align="center">-</td>
                        <td v-if="object.maxTemp" align="center">{{ object.maxTemp }}&deg;C</td>
                        <td v-else align="center">-</td>
                        <td align="center">
                            <span v-if="object.enabled" :class="`tag temp-${object.tempStatus}`">{{ getText(`temp-status-${object.tempStatus}`) }}</span>
                            <span v-else>-</span>
                        </td>
                        <td align="center">
                            <span v-if="object.enabled" :class="`tag sensor-${object.sensorStatus}`">{{ getText(`sensor-status-${object.sensorStatus > 0 ? 1 : 0}`) }}</span>
                            <span v-else>-</span>
                        </td>
                        <td>{{ object.type }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p v-if="tableTs" class="table-detail">{{ getText("last-update") }}: {{ tableTs }}</p>
    </div>
</template>

<script>
    import axios from "axios"
    import Vue from "vue"
    import { mapActions, mapGetters } from "vuex"

    export default {
        name: "StatusTabular",
        props: ["reloadTs"],
        data() {
            return {
                objects: [],
                tableStatus: 0,
                tableTs: null
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),

        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            tryLoadObjects: function () {
                this.replaceLoader({ loader: true });
                //this.objects = [];
                this.tableStatus = 0;
                return axios.get("/api/GetAllCoolingObjectStatus").then(res => {
                    if (res.data.requestSuccess) {
                        Vue.set(this, "objects", res.data.result);
                        this.tableStatus = 1;
                        this.tableTs = new Date().toLocaleString();
                    } else {
                        throw (res.data.message);
                    }
                }).catch(e => {
                    alert("Failed to load status: " + e);
                    this.tableStatus = 2;
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            viewObject: function (data) {
                data.tableTs = this.tableTs;
                this.$emit("viewObject", data );
            },
            inferTargetObject: function () {
                try {
                    if (this.$route.query.coid) {
                        var obj = this.objects.find(el => el.id == this.$route.query.coid);
                        if (obj == null) throw "The cooling object ID provided was either invalid, or you may not have the necessary permission to access the cooling object.";
                        this.viewObject(obj);
                    }
                } catch (e) {
                    alert(e);
                    let query = Object.assign({}, this.$route.query);
                    delete query.coid;
                    this.$router.replace({ query }).catch(e => {
                        if (e.name != "NavigationDuplicated") throw e;
                    });
                }
            },
            keySort: function (arr, key) {
                arr.sort((a, b) => {
                    return a[key] > b[key] ? 1 : -1;
                });
            },
            init: async function () {
                await this.tryLoadObjects();
                this.inferTargetObject();
            }
        },
        watch: {
            reloadTs: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .status {
        position: relative;
        padding-left: var(--margin-05);
    }

        .status::after {
            width: var(--margin-025);
            height: var(--margin-025);
            content: " ";
            border-radius: 50%;
            position: absolute;
            top: calc(0.75 * var(--medium) - 0.5 * var(--margin-025));
            left: 0;
            border: 2px solid var(--iss-grey);
        }

        .status.enabled::after {
            border: 0;
            background: var(--free-green);
        }
</style>