<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['manager'])">
            <UsersByRolePage></UsersByRolePage>
        </div>
        <StandardRedirect v-else :targetRoute="'/'"></StandardRedirect>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import StandardRedirect from "@/components/default/StandardRedirect.vue"
    import UsersByRolePage from "@/components/role-management/Users.vue"

    export default {
        name: "TypeManagement",
        components: {
            UsersByRolePage,
            StandardRedirect
        },
        computed: {
            ...mapGetters("keycloak", {
                hasClientRoles: "hasClientRoles"
            })
        }
    }
</script>

<style scoped>
    .dexcon-check {
        color: var(--azure-blue);
        line-height: calc(1.4 * var(--medium));
    }

    td > label {
        margin: 0;
    }

    .slideLeft-enter-active {
        animation: slide-left var(--quick);
    }

    .slideLeft-leave {
        animation: slide-left var(--quick) reverse;
    }

    @keyframes slide-left {
        0% {
            opacity: 0;
            transform: translate(5%, 0);
        }

        100% {
            opacity: 1;
            transform: translate(0);
        }
    }

    .slideRight-enter-active {
        animation: slide-right var(--quick);
    }

    .slideRight-leave {
        animation: slide-right var(--quick) reverse;
    }

    @keyframes slide-right {
        0% {
            opacity: 0;
            transform: translate(-5%, 0);
        }

        100% {
            opacity: 1;
            transform: translate(0);
        }
    }
</style>