<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['supermanager'])">
            <h2>{{ getText("interfaces") }}</h2>
            <div v-show="loaded.smc">
                <h3>{{ getText("smc-short") }}</h3>
                <div class="label-group">
                    <p class="label">{{ getText("options") }}</p>
                    <label>
                        <input type="checkbox" v-model="innerData.enabled" />
                        <span>{{ getText("enabled") }}</span>
                    </label>
                </div>
                <div class="label-group">
                    <p class="label">{{ getText("email") }}</p>
                    <input type="email" v-model="innerData.email" />
                </div>
                <div class="button-wrapper center">
                    <button v-if="valueChanged" class="box outline" @click="revertChanges">{{ getText("revert") }}</button>
                    <button class="box" :disabled="!valueChanged" @click="tryUpdateSmcInformation">{{ getText("save") }}</button>
                </div>
            </div>
            <p v-show="!loaded.smc">{{ getText("table-status-0") }}</p>
        </div>
        <StandardRedirect v-else :targetRoute="'/'"></StandardRedirect>
    </div>
</template>

<script>
    import axios from "axios"
    import { mapActions, mapGetters } from "vuex"
    import StandardRedirect from "@/components/default/StandardRedirect.vue"

    export default {
        name: "Interfaces",
        components: {
            StandardRedirect
        },
        data() {
            return {
                loaded: {
                    smc: false
                },
                initData: {
                    email: null,
                    enabled: false
                },
                innerData: {
                    email: null,
                    enabled: false
                },
                valueChanged: false
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getStatus: "getStatus",
                hasClientRoles: "hasClientRoles"
            })
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            tryLoadSmcInformation: function () {
                this.replaceLoader({ loader: true });
                return axios.get("/api/smcinformation").then(res => {
                    if (res.data.requestSuccess) {
                        this.initData.email = res.data.result.smcMail;
                        this.initData.enabled = res.data.result.smcEnabled;
                        this.innerData = Object.assign({}, this.initData);
                    } else {
                        throw res.data.message;
                    }
                }).catch(e => {
                    alert("Failed to load SMC information: " + e);
                }).finally(() => {
                    this.loaded.smc = true;
                    this.replaceLoader({ loader: false });
                })
            },
            tryUpdateSmcInformation: function () {
                this.replaceLoader({ loader: true });
                return axios.post("/api/smcinformation", {
                    smcEnabled: this.innerData.enabled,
                    smcMail: this.innerData.email
                }).then(res => {
                    if (res.data.requestSuccess) {
                        this.tryLoadSmcInformation();
                    } else {
                        throw res.data.message;
                    }
                }).catch(e => {
                    alert("Failed to update SMC information: " + e);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            revertChanges: function () {
                this.innerData = Object.assign({}, this.initData);
            },
            async init() {
                if (!this.hasClientRoles(["supermanager"])) return;
                this.tryLoadSmcInformation();
            }
        },
        watch: {
            getStatus: function () {
                this.init();
            },
            innerData: {
                deep: true,
                handler() {
                    let diffReducer = (sum, obj) => { return obj[1] != this.initData[obj[0]] ? sum + 1 : sum; };
                    this.valueChanged = Object.entries(this.innerData).reduce(diffReducer, 0) > 0 ? true : false;
                }
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    label {
        display: inline-block !important;
    }

    input[type=email] {
        width: calc(25 * var(--medium));
        max-width: 100%;
    }
</style>