export default ({
    namespaced: true,
    state: {
        targetUnit: null
    },
    getters: {
        getConvertedTemperatureAsString: () => (initValue, initUnit) => {
            if (isNaN(parseFloat(initValue))) return "-";
            // Convert to C
            switch (initUnit) {
                case "fahrenheit":
                    initValue = (initValue - 32) / 1.8;
                    break;
                default:
                    break;
            }
            // Convert to target unit
            switch (window.localStorage.getItem("unit")) {
                case "celcius":
                    return Math.round(initValue * 100) / 100 + "&deg;C";
                case "fahrenheit":
                    return Math.round(((initValue * 1.8) + 32) * 100) / 100 + "&deg;F";
                default:
                    return "Target unit not supported";
            }
        },
        getConvertedTemperatureAsFloat: () => (initValue, initUnit, targetUnit) => {
            if (isNaN(parseFloat(initValue))) return null;
            // Convert to C
            switch (initUnit) {
                case "fahrenheit":
                    initValue = (initValue - 32) / 1.8;
                    break;
                default:
                    break;
            }
            // Convert to target unit
            switch (!targetUnit ? window.localStorage.getItem("unit") : targetUnit) {
                case "celcius":
                    return Math.round(initValue * 100) / 100;
                case "fahrenheit":
                    return Math.round(((initValue * 1.8) + 32) * 100) / 100;
                default:
                    return null;
            }
        },
        getUnitShortHand: function (state) {
            let units = {
                fahrenheit: "°F",
                celcius: "°C"
            };
            return units[state.targetUnit];
        }
    },
    mutations: {
        setUnit: function (state, payload) {
            if (!payload.unit) return;
            state.targetUnit = payload.unit;
            window.localStorage.setItem("unit", payload.unit);
        }
    },
    actions: {
        replaceUnit: function (context, payload) {
            context.commit("setUnit", payload);
        }
    }
})