<template>
    <div>
        <h2>Unauthorized</h2>
    </div>
</template>

<script>
    export default {
        name: "UnauthorizedRedirect",
        props: ["targetRoute"],
        mounted() {
            if (!this.targetRoute) return;
            this.$router.push(this.targetRoute);
        }
    }
</script>