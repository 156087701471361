<template>
    <div class="spatial-model-group-wrapper">
        <button class="spatial-model-group" :class="{'is-expanded': toggle.isExpanded, 'last-nest': lastNest}" @click="toggle.isExpanded = !toggle.isExpanded">
            <div class="header">
                <i class="dexcon-chevron-down"></i>
                <span>{{ name }}</span>
            </div>
            <div class="alerts">
                <span v-if="criticalTemp">
                    <i class="dexcon-temperature-3"></i>
                    <span>{{ criticalTemp }}</span>
                    <span>/ {{ totalCo }}</span>
                </span>
                <span v-if="criticalSensor">
                    <i class="dexcon-warn"></i>
                    <span>{{ criticalSensor }}</span>
                    <span>/ {{ totalCo }}</span>
                </span>
            </div>
        </button>
        <div>
            <slot v-if="this.toggle.isExpanded"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SpatialModelGrouping",
        props: ["data", "lastNest"],
        data() {
            return {
                toggle: {
                    isExpanded: false
                }
            }
        },
        computed: {
            name: function () {
                let names = ["state", "city", "building", "floor", "room"];
                let targetName = "n/a";
                names.map(o => {
                    if (Object.keys(this.data).indexOf(o + "Name") > -1) targetName = this.data[o + "Name"];
                });
                return targetName;
            },
            criticalTemp: function () {
                return this.data.criticalTemp;
            },
            criticalSensor: function () {
                return this.data.criticalSensor;
            },
            totalCo: function () {
                return this.data.totalCo;
            }
        }
    }
</script>

<style scoped>
    .spatial-model-group {
        text-align: left;
        width: 100%;
        padding: var(--margin-05);
        border-radius: 0;
        margin-top: var(--margin-025);
        display: flex;
        flex-wrap: wrap;
    }

    .spatial-model-group {
        border-bottom: var(--iss-grey) solid 1px;
    }

        .spatial-model-group::after {
            border-radius: 0;
            background: none;
        }

        .spatial-model-group > div:first-child {
            flex-grow: 1;
            white-space: normal;
        }

    .header > span {
        font-size: var(--larger);
        font-weight: var(--bolder);
    }

    .dexcon-chevron-down {
        margin-right: var(--margin-025);
    }

        .dexcon-chevron-down::before {
            display: inline-block;
            transform: rotate(-90deg) !important;
        }

    .is-expanded > div > .dexcon-chevron-down::before {
        transform: rotate(0) !important;
    }

    .spatial-model-group + div {
        margin-left: var(--margin-05);
    }

    .alerts > *:not(:first-child) {
        margin-left: var(--margin-05);
    }

    .alerts > * > i {
        color: var(--iss-red);
        margin-right: var(--margin-025);
    }

    .alerts > * > span:nth-child(2) {
        font-weight: var(--bolder);
    }

    .alerts > * > span:last-child {
        font-size: var(--small);
        margin-left: var(--margin-0125);
    }

    .is-expanded:not(.last-nest) .alerts {
        opacity: 0.3;
    }
</style>