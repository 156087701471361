import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/default/Home.vue'
import Impressum from '../views/default/Impressum.vue'
import PageNotFound from '../views/default/PageNotFound.vue'
import Account from '../views/default/Account.vue'

import Status from '../views/status/Status.vue'
import TypeManagement from '../views/coolingObjectTypes/CoolingObjectTypes.vue'
import UserManagement from '../views/UserManagement.vue'
import RoleManagement from '../views/RoleManagement.vue'
import Interfaces from '../views/Interfaces.vue'

Vue.use(VueRouter)

const routes = [{
        path: '*',
        name: 'Page not found',
        component: PageNotFound
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: Impressum
    },
    {
        path: '/account',
        name: 'Account',
        component: Account
    },
    {
        path: '/overview',
        name: 'Overview',
        component: Status
    },
    {
        path: '/roles',
        name: 'Roles',
        component: RoleManagement
    },
    {
        path: '/users',
        name: 'Users',
        component: UserManagement
    },
    {
        path: '/classes',
        name: 'Classes',
        component: TypeManagement
    },
    {
        path: '/interfaces',
        name: 'Interfaces',
        component: Interfaces
    }
]


const router = new VueRouter({
    routes,
    mode: "history"
})

router.afterEach((to, from) => {
    if (to.path == from.path) return;
    document.querySelector("html").scrollTo(0, 0);
});

export default router